import { Component, OnInit, Input, HostListener, ViewContainerRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
	selector: 'switch-toggle',
	templateUrl: './switch-toggle.component.html',
	styleUrls: ['./switch-toggle.component.less'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SwitchToggleComponent),
			multi: true
		}
	]
})
export class SwitchToggleComponent implements ControlValueAccessor {

	//holds the value
	@Input('value') _value = null;

	@Input('positive') positive: string = 'Ja';
	@Input('negative') negative: string = 'Nee';

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor(private viewContainerRef: ViewContainerRef,
				) { }

	/** 
    * checks which class the background needs to have
	*
	* @author 		Wilfred van Eck <wilfred@safira.nl>
	*/
	checkClass() {
		if (this.value == 1) { return 'positive'; }
		if (this.value == 0) { return 'negative'; }

		return '';
	}

	/** 
    * Set the positve status
	*
	* @author 		Wilfred van Eck <wilfred@safira.nl>
	*/
	toggleValue() {
		this.value == 1 ? this.value = 0 : this.value = 1;
	}

	/** 
    * Updates the value of the input
	*
	* @author 		Wilfred van Eck <wilfred@safira.nl>
	*/
	update(event) {
		this.value = event.target.value;
	}

	/**
    * getter
	*
	* @author 		Wilfred van Eck <wilfred@safira.nl>
	*/
	get value() {
		return this._value;
	}
	
	/**
    * setter
	*
	* @author 		Wilfred van Eck <wilfred@safira.nl>
	*/
	set value(val) {
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}
	
	/**
    * Registers the onChange function
	*
	* @author 		Wilfred van Eck <wilfred@safira.nl>
	*/
	registerOnChange(fn) {
		this.onChange = fn;
	}
	
	/**
    * Registers the onTouch function
	*
	* @author 		Wilfred van Eck <wilfred@safira.nl>
	*/
	registerOnTouched(fn) { 
		this.onTouched = fn;
	}
	
	/**
    * Writes the value
	*
	* @author 		Wilfred van Eck <wilfred@safira.nl>
	*/
	writeValue(value) {
		this.value = value;
	}

}
