import { Component, OnInit, Input, forwardRef, ViewContainerRef, ViewChild } from '@angular/core';
import { MyTasksService } from 'src/app/models/myTasks/my-tasks.service';
import { Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TasksModel } from 'src/app/models/tasks/tasks.service';
import { Subscription } from 'rxjs';
import { TaskRepeatPopupComponent } from '../task-repeat-popup/task-repeat-popup.component';


@Component({
	selector: 'task-dashboard-list',
	templateUrl: './task-dashboard-list.component.html',
    styleUrls: ['./task-dashboard-list.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => taskDashboardListComponent),
            multi: true
        },
    ]
})
export class taskDashboardListComponent implements OnInit, ControlValueAccessor {

    writeValue(value) {
        this.currentBigBlock = value;
    }
    registerOnChange(fn: any): void {
    }
    registerOnTouched(fn: any): void {
    }


    constructor(public taskService: TasksModel ,public myTaskService: MyTasksService, private router: Router, private viewContainerRef: ViewContainerRef) {}

    public selectedTaskData;
    public repeatingTaskInfo;
    public tasks;
    public taskResultsFiltered;
    public deadlineStatus;
    public bigBlock: boolean = false;
    private filterSubscription:    Subscription;
    public  filterSet = []; //holds the filterSet

    ngOnInit() {
        this.myTaskService.getMyDashboardTasks(this.curModule['workflow_module_id']).then(result => {
            this.tasks = result;
            this.taskResultsFiltered = result;
            // this.applyFilter();
            this.myTaskService.updateDashboardFilters(this.tasks);
        });

        // subscribe to changes of the filter in the filter service
        this.filterSubscription = this.myTaskService.dashboardFilterChanged.subscribe(
            (filterSet) => {
                this.filterSet = filterSet;
                this.applyFilter()
            }
        )

    }

    ngOnDestroy() {
        // unsubscribe on filter and paginator on destroy.
        this.filterSubscription.unsubscribe();
    }

    @ViewChild('repeatingTaskPopup') popup: TaskRepeatPopupComponent;

    @Input('module') curModule: object;

    @Input('currentBigBlock') currentBigBlock: any;

    @Input('updateBackFunction') updateBackFunction: any;


    	  /**
	* checkDeadline
	*
	* Check color
	*
	* @author      	Romain van Maanen <romain@safira.nl>
	* @lastEdit     21-11-2018 10:33
	* @lastEditBy   Romain van Maanen <romain@safira.nl>
	*/
	public checkDeadline(stringDate){
		const currentDate = new Date().toJSON().slice(0, 10);
		if(currentDate == stringDate){
		  this.deadlineStatus = "Deze week";
		  return 'green';
		} else if(currentDate < stringDate){
		  this.deadlineStatus = "Deze week";
		  return 'green';
		}else{
		  this.deadlineStatus = "Deadline verstreken";
		  return 'red';
		}
	}

    /**
     * userName
     *
     * formats the user name
     *
     * @param firstName
     * @param insertion
     * @param lastName
     */
    public userName(firstName, insertion, lastName){
        var firstName = ( firstName != null) ? firstName : '';
        var insertion = ( insertion != null) ? insertion : '';
        var lastName = ( lastName != null) ? lastName : '';
        return firstName + " " + insertion + " " + lastName;
    }


    public openDetails(id) {
        this.router.navigateByUrl('/details/' + id);
    }

    public expandBlock() {
        this.bigBlock = true;
        this.writeValue(this.curModule['workflow_module_id']);
        if(typeof this.updateBackFunction !== "undefined") this.getParentComponent()[this.updateBackFunction](this.currentBigBlock);
    }

    public downsizeBlock() {
        this.bigBlock = false;
        this.writeValue(null);
        if(typeof this.updateBackFunction !== "undefined") this.getParentComponent()[this.updateBackFunction](this.currentBigBlock);
    }


    /**
     * gets the parent component
     */
    private getParentComponent(){
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
    }


    public async openRepeatingPopup(task: any): Promise<void> {
        if(this.popup.isOpen) this.popup.isOpen = false;

        this.repeatingTaskInfo = await this.taskService.getRepeatingTaskInfo(task);
        this.selectedTaskData = task;

        this.repeatingTaskInfo.frequency = ERepeatingTaskName[this.repeatingTaskInfo.frequency]
        this.repeatingTaskInfo = this.repeatingTaskInfo

        this.popup.repeatingTaskInfo = this.repeatingTaskInfo
        this.popup.isOpen = true;
    }

     /**
     * createSingleTask
     *
     * navigates to the page to create a single task
     */
    public createSingleTask() {
        this.router.navigate(['/myTasks/create'])
    }

    public applyFilter() {
        this.taskResultsFiltered = [];
        let firstFilter = true;
        // for each filter, filter the objects
        for (const filter in this.filterSet) {
            if (this.filterSet.hasOwnProperty(filter) && this.filterSet[filter] != null) {
                // when its the first filter, take results from the full task results
                if(firstFilter) {
                    for (const index in this.tasks) {
                        let task = this.tasks[index];
                        if(filter == 'financial_year' && task[filter] == this.filterSet[filter]) {
                            if(!this.taskResultsFiltered.some(e => e.workflow_task_id === task.workflow_task_id)) this.taskResultsFiltered.push(task);
                        }
                        if(filter == 'task' && ((task['task_order'] != '0') ? task['task_order']+' | '+task['task_name'] == this.filterSet['task'] : task['task_order'] == this.filterSet['task'].match(/\d+/))){
                            if(!this.taskResultsFiltered.some(e => e.workflow_task_id === task.workflow_task_id)){

                                this.taskResultsFiltered.push(task);
                            }
                        }
                        firstFilter = false;
                    }
                // not first filter, then take the results of the filtered object to filter out the rest
                } else {
                    for (const index in this.taskResultsFiltered) {
                        // filter matches
                        if(filter == 'financial_year') this.taskResultsFiltered = this.taskResultsFiltered.filter(task => task[filter] == this.filterSet[filter]);
                        else {
                            this.taskResultsFiltered = this.taskResultsFiltered.filter(task => (task['task_order'] != '0') ? task['task_order']+' | '+task['task_name'] == this.filterSet['task'] : task['task_order'] == this.filterSet['task'].match(/\d+/))
                        }
                    }
                }
            }
        }

        // check if any tasks are in th efiltered object
        if(!this.taskResultsFiltered[0] || this.taskResultsFiltered.length === 0) {
            // filtered is empty, check if any filters where ste
            if(this.filterSet['financial_year'] == null && this.filterSet['task'] == null) {
                // no filters set, so set the full results
                this.taskResultsFiltered = this.tasks;
            } else {
                // filters are set, but no tasks in the filtered object, that means there are no results on this filter setting
                this.taskResultsFiltered = [];
            }
        }

    }
}

enum ERepeatingTaskName {
    "NONE" = "Geen",
    "WEEKLY" = "Elke week",
    "MONTHLY" = "Elke maand",
    "QUARTERLY" = "Elk kwartaal",
    "ANNUALLY" = "Elk jaar"
}