import { ModuleSettingsPopupComponent } from "./views/module-settings-popup/module-settings-popup.component";
import { NgModule } from '@angular/core';
import { FormModule } from "src/app/core/components/form/form.module";
import { CommonModule } from "@angular/common";
import { PopupModule } from "src/app/core/components/popup/popup.module";
import { ModuleSettingsDetailComponent } from "./views/module-settings-detail/module-settings-detail.component";
import { FormsModule } from "@angular/forms";
import { ConfirmboxModule } from "src/app/core/components/confirmbox/confirmbox.module";

@NgModule({
	imports: [
        FormModule,
        CommonModule,
        PopupModule,
        FormsModule,
        ConfirmboxModule,
	],
	declarations: [
        ModuleSettingsPopupComponent,
        ModuleSettingsDetailComponent,
    ],
    exports: [
        ModuleSettingsPopupComponent,
        ModuleSettingsDetailComponent,
    ]
})
export class WorkflowModulesModule {

}
