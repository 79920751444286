import { Directive, HostListener, OnInit } from '@angular/core';
import { UtilService } from '../../services/util/util.service';

const ATTRIBUTE_NAME = 'tab-number';
const ACTIVE_TAB = 'active-tab';
const ACTIVE_CONTENT = 'active-content';
const CONTENT_WRAPPER_CLASS = 'tab-wrapper';
const CONTENT_CLASS = 'content-tab';
const TAB_ITEM_CLASS = 'tab-item';
const WRAPPER_ATTRIBUTE = 'wrapper-number';
const LIST_ITEM_ATTRIBUTE = 'tabNumber';
const LIST_CLASS = 'tabs-list';

@Directive({
	selector: '[data-tab]'
})
export class TabDirective implements OnInit{
	private _tabsList: any = {};
	private _scrollButtons: any = {};

	constructor(
		public util:UtilService
	) { }

	ngOnInit() {
		this._tabsList = this.util.getElements('.tabs-list');
		this._scrollButtons = this.util.getElements('.scroll');
	}

	/**
    * onClick
    *
    * Check on which tab the user clicked and toggle the content accordingly.
    *
    * @author       Jurre Kon <jurre@safira.nl>
    * @lastEdit     19-07-2018 11:37
    * @lastEditBy   Jurre Kon <jurre@safira.nl>
    */
	@HostListener("click", ["$event"])
	onClick(event) :void{
		let clickedElement = event.target;
		if(event.target.className === TAB_ITEM_CLASS || this.util.findParentWithClass(event.target ,TAB_ITEM_CLASS)){
			if(this.util.findParentWithClass(event.target ,TAB_ITEM_CLASS)){
				clickedElement = this.util.findParentWithClass(event.target ,TAB_ITEM_CLASS);
			}
			let parentUL = this.util.findParentWithClass(clickedElement, LIST_CLASS);
			let wrapperNumber = this.util.getAttribute(parentUL, WRAPPER_ATTRIBUTE);
			let childListArray = this.util.findChildrenWithClass(parentUL, TAB_ITEM_CLASS);

			for(let i = 0; i < childListArray.length; i++){
				this.util.removeClass(childListArray[i], ACTIVE_TAB);
			}

			this.util.addClass(clickedElement, ACTIVE_TAB);
			
			let activeItemnumber = this.util.getAttribute(clickedElement, ATTRIBUTE_NAME);
			let tabContentWrapper = this.util.getElements(`.${CONTENT_WRAPPER_CLASS}`);

			for(let i = 0; i < tabContentWrapper.length; i++){
				if(this.util.getAttribute(tabContentWrapper[i], WRAPPER_ATTRIBUTE) === wrapperNumber){
					let currentActiveTab = this.util.getChild(tabContentWrapper[i], ACTIVE_CONTENT);

					this.util.removeClass(currentActiveTab, ACTIVE_CONTENT);

					let childContentArray = this.util.getElements(`.${CONTENT_CLASS}`);

					for(let x = 0; x < childContentArray.length; x++){
						if(this.util.getAttribute(childContentArray[x], ATTRIBUTE_NAME) === activeItemnumber){
							this.util.addClass(childContentArray[x], ACTIVE_CONTENT);
						}
					}
				}
			}
		}

		for (let i = 0; i < this._tabsList.length; i++) {
			if (this._tabsList[i].offsetWidth < this._tabsList[i].scrollWidth) {
				for (let x = 0; x < this._scrollButtons.length; x++) {
					if (this.util.getAttribute(this._scrollButtons[x], LIST_ITEM_ATTRIBUTE) == i) {
						this._scrollButtons[x].style.display = 'block';
					}
				}
			} else if (this._tabsList[i].offsetWidth >= this._tabsList[i].scrollWidth) {
				for (let x = 0; x < this._scrollButtons.length; x++) {
					if (this.util.getAttribute(this._scrollButtons[x], LIST_ITEM_ATTRIBUTE) == i) {
						this._scrollButtons[x].style.display = 'none';
					}
				}
			}
		}
	}
}
