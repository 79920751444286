import { Directive, HostListener,ElementRef  } from '@angular/core';

@Directive({
	selector: '[custom-input]'
})
export class InputDirective {

	constructor(public el?: ElementRef) { 
	}

}
