import { Injectable } from '@angular/core';
import { AjaxService } from 'src/app/core/services/ajax/ajax.service';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MyTasksService {

    public dashboardFilters = {
        'financial_years': [],
        'tasks': []
    }

    public dashboardFilterChanged = new Subject<any>();
    public activeFilter = {
        'financial_year': null,
        'task': null
    }

	constructor(
		private http: AjaxService
	) { }

	/*
	  *	getUserTasks
	  *
	  *	Returns all user info
	  */
	getUserTasks() {
		let promise = new Promise((resolve, reject) => {
			this.http.get('myTasks/getUserTasks', '.content').then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
    }


    /**
     * getMyDasboardTasks
     *
     * gets overview of not completed tasks for th edashboard list by module id
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param $moduleId required
     */
    public getMyDashboardTasks($moduleId) {
        let promise = new Promise((resolve, reject) => {
            let data = {'moduleId': $moduleId};
            this.http.post('myTasks/getMyDashboardTasks', data).then(
                res => {
                    resolve(res);
                },
                msg => {
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /*
     * updateSingleTaskToEmployee
     *
     * calls the endpoint to update a single task to a new employee
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param $taskId
     * @param $employeeId
     */
    public updateSingleTaskToEmployee($taskId, $employeeId) {
        let promise = new Promise((resolve, reject) => {
            let data = {'taskId': $taskId, 'employeeId': $employeeId};
            this.http.post('myTasks/updateSingleTaskToEmployee', data).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
    }

    public updateDashboardFilters(data) {
        data.forEach(line => {
            // handle financial year
            if(!this.dashboardFilters['financial_years'].includes(line['financial_year'])) this.dashboardFilters['financial_years'].push(line['financial_year']);
            if(!this.dashboardFilters['tasks'].includes(line['task_order']+' | '+line['task_name'])) {
                let taskname = (line['task_order'] == '0') ? 'Losse taak' : line['task_name'];
                if(!this.dashboardFilters['tasks'].some(e => e == (line['task_order']+' | '+taskname))) this.dashboardFilters['tasks'].push(line['task_order']+' | '+taskname);
            }
        });

        // sort the columns Ascending
        this.dashboardFilters['financial_years'].sort(function(a, b){
            if(+a.match(/\d+/) < +b.match(/\d+/)) return -1;
            if(+a.match(/\d+/) > +b.match(/\d+/)) return 1;
            return 0;
        })

        this.dashboardFilters['tasks'].sort(function(a, b){
            if(+a.match(/\d+/) < +b.match(/\d+/)) return -1;
            if(+a.match(/\d+/) > +b.match(/\d+/)) return 1;
            return 0;
        })

    }

    public dashboardOnFilterChange(event, type) {
        this.activeFilter[type] = (event.target.value == 'null') ? null : event.target.value;
        this.dashboardFilterChanged.next(this.activeFilter);
    }
}
