import { Component, Input, ViewEncapsulation, ViewContainerRef, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { UtilService } from '../../services/util/util.service';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent {

	//holds the title
	@Input('title') title: string;

	//holds the subtitle
	@Input('subtitle') subtitle: string;

	//wehter the title has a contrast background
	@Input('contrast') contrast;

	//wehter no margin & padding in the card body
	@Input('fill') fill;

	//get the html refrence for content
	@Input('menu') menu;

	@Input() parent;


	constructor(private viewContainerRef: ViewContainerRef, private cdRef:ChangeDetectorRef, private elt:ElementRef, public util:UtilService){}

	ngAfterContentInit() {

	}

	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}

	getParentComponent(){
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
	}

	clickMenuItem( functionName ) {
		try {
			 this.getParentComponent()[functionName]();
		} catch (e) {
			console.log("ERROR: function "+functionName+" not found.");
		}
	}

}
