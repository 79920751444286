import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	// Default API URL
	public apiUrl: string = 'http://accept.simpelplanning.nl/api/v2.0/';
	public rememberMe: boolean = false;

	constructor() {

		if (window.location.host === "accept.simpelplanning.nl") {
			this.apiUrl = 'https://accept.simpelplanning.nl/api/v2.0/';
        }
        else if (window.location.host.includes("simpelplanning-test.safira-test.nl")) {
			this.apiUrl = 'http://simpelplanning-test.safira-test.nl/api/v2.0/';
		}
        else if (window.location.host === "app.simpelplanning.nl") {
			this.apiUrl = 'https://app.simpelplanning.nl/api/v2.0/';
		}
		else if (window.location.host === "127.0.0.1:4200" || window.location.host === "localhost:4200") {
			this.apiUrl = 'http://simpelplanning-dev.safira-test.nl/api/v2.0/';
		} else {
			this.apiUrl = 'http://simpelplanning.nl/api/v2.0/';
		}
	}
}
