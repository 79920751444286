import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmboxComponent } from './confirmbox.component';


@NgModule({
	declarations: [
		ConfirmboxComponent
	],
	imports: [
		CommonModule
	],
	exports: [
		ConfirmboxComponent
	],
	providers: [
		ConfirmboxComponent
	]
})
export class ConfirmboxModule { }