import { Component, OnInit, ElementRef, ViewEncapsulation, Input } from '@angular/core';
import { ValidationService } from './../../services/validation/validation.service';
import { UtilService } from '../../services/util/util.service';

const ERRORTEXT = 'error-text';
const VALIDTEXT = 'valid-text';
const MINUSOFFSETTOP = 100;

@Component({
  selector: 'sForm',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class FormComponent implements OnInit {

	@Input('buttonText') buttonText: string;
	
  	constructor(private elt:ElementRef, private validationService: ValidationService, private util: UtilService) { }

	ngOnInit() { }

  /**
    * validateContent
    *
    * Validate all possible input types
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  	validateContent(): void {
		let valid 		= true;
		let errorText 	= '';

		// Get all elements in the card body
		let form 		= this.elt.nativeElement.childNodes[0];
		let elements 	= form.childNodes;
		var tmpThis 	= this;

		Array.prototype.forEach.call(elements, (element) => {
			let myFunction = `tmpThis.validationService.${element.localName}Validate(element)`;

			if (this.elementValidationPossible(element.localName)) {			
				let myFunctionReturnValues = eval(myFunction);
				if (!myFunctionReturnValues[0]) {
					errorText = errorText + `${myFunctionReturnValues[1]}<br/>`;
					valid = false;
				}
			}
		});

		// Importing right text in card
		Array.prototype.forEach.call(elements, (element) => {
			if (element.classList && element.classList.contains('validation-text')) {
				if (!valid) {
					element.classList.add(ERRORTEXT);	
					element.classList.remove('hide');

					element.innerHTML = errorText;
				} else {
					element.classList.remove(ERRORTEXT);
					element.classList.add(VALIDTEXT);
					element.classList.add('hide');
				}
			}
		});

		/* gives the user feedback */
		 if (!valid) {
			var offsetTop = this.util.getOffset(elements[0]).top; 
			window.scrollTo({top: offsetTop - MINUSOFFSETTOP, behavior: 'smooth'});
		 } else {
		 	this.openFormSucces(form);
		 }
	}

  /**
    * validationPossible
    *
    * is there a element thats possible to validate
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
	validationPossible(): boolean {
		return this.validationService.validationPossible(this.elt.nativeElement);
	}

  /**
    * elementValidationPossible
    *
    * is it possible to validate this element
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
	elementValidationPossible(localName: string): boolean {
		return this.validationService.elementValidationPossible(localName);
	}


	/**
	* openFormSucces
	*
	* opens the succes form and shadow
	*
	* @author       Wilfred van Eck <wilfred@safira.nl>
	* @lastEdit     10-09-2018 14:14
	* @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
	*/
	openFormSucces(form: any) {		
		for (let index = 0; index < form.children.length; index++) {
			const child = form.children[index];
			if(child.classList.contains('form-succes') || child.classList.contains('form-succes-shadow')){
				child.classList.remove('hide');
			}
		}
		this.lockBody(form);
	}


	/**
	* closeFormSucces
	*
	* closes the succes form and shadow
	*
	* @author       Wilfred van Eck <wilfred@safira.nl>
	* @lastEdit     10-09-2018 14:14
	* @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
	*/
	closeFormSucces() {
		let form = this.elt.nativeElement.childNodes[0];
		for (let index = 0; index < form.children.length; index++) {
			const child = form.children[index];
			if(child.classList.contains('form-succes') || child.classList.contains('form-succes-shadow')){
				child.classList.add('hide');
			}
		}
		this.unlockBody(form);
	}


	/**
	* lockBody
	*
	* locks the scroll from the body
	*
	* @author       Wilfred van Eck <wilfred@safira.nl>
	* @lastEdit     13-08-2018 11:25
	* @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
	*/
	lockBody(form: any) {
		let scrollTop 	= window.pageYOffset;
		let content 	= form.parentNode.parentNode.parentNode.parentNode.parentNode;

		content.style.top 				= scrollTop;
		document.body.style.height 		= '100%';
		document.body.style.overflow 	= 'hidden';
	}	


	/**
	* unlockBody
	*
	* unlocks the scroll from the body
	*
	* @author       Wilfred van Eck <wilfred@safira.nl>
	* @lastEdit     13-08-2018 11:25
	* @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
	*/
	unlockBody(form: any) {
		let scrollTop 	= window.pageYOffset;
		let content 	= form.parentNode.parentNode.parentNode.parentNode.parentNode;

		document.body.style.height 		= '';
		document.body.style.overflow 	= '';
		content.style.top 				= '';

		window.scrollTo(0, scrollTop);
	}

	
	

}
