import { Injectable } from '@angular/core';
import { AjaxService } from '../../core/services/ajax/ajax.service';
import { CustomerService } from '../customer/customer.service';

@Injectable({
	providedIn: 'root'
})

export class WorkflowModuleService {
    public periodList: any;

    constructor(private http: AjaxService, private customerService: CustomerService) {}

    /**
     * getPeriods
     *
     * returns a list of all periods used for workflows
     *
     * @author Eric van Doorn <eric@safira.nl>
     */
    public getPeriods() {
        let promise = new Promise((resolve, reject) => {
            this.http.get('workflowModules/getPeriods').then(
                res => { // Success
                    resolve(res);
                },
                msg => { // Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
     * updatePeriodOnOffice
     *
     * updates the periods of the given module and office id
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param officeId
     * @param moduleId
     * @param periodId
     */
    public updatePeriodOnOffice(officeId, moduleId, periodId) {
        let promise = new Promise((resolve, reject) => {
            let data = {officeId: officeId, moduleId: moduleId, periodId: periodId}
            this.http.post('workflowModules/updatePeriodOnOffice', data, '.cfg-wrapper').then(
                res => {
                    resolve(res);
                },
                msg => {
                    reject(msg);
                }
            );
        });
        return promise;
    }


    /**
     * updatePeriodOnCompany
     *
     * updates the periods of the given module and office id
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param companyId
     * @param moduleId
     * @param periodId
     */
    public updatePeriodOnCompany(companyId, moduleId, periodId) {
        let promise = new Promise((resolve, reject) => {
            let data = {companyId: +companyId, moduleId: +moduleId, periodId: +periodId}
            this.http.post('workflowModules/updatePeriodOnCompany', data, '.cfg-wrapper').then(
                res => {
                    this.customerService.searchCustomers()
                    resolve(res);
                },
                msg => {
                    reject(msg);
                }
            );
        });
        return promise;
    }
}
