import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../../../models/user/user.service';
import { ToasterService } from '../../../../core/services/toaster/toaster.service';
import { AuthorisationService } from '../../../../core/services/authorisation/authorisation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.less']
})
export class RegisterComponent{
	formFields: any[] = [];

	constructor(
		public user:UserModel,
		public toast: ToasterService,
		public auth:AuthorisationService,
		public route:ActivatedRoute
	) {
		this.route.params.subscribe(params => {
			if(typeof params['hash'] != 'undefined'){
				this.user.activateUser(params['hash']).then(result => {
					this.toast.success('Account activated');
					this.auth.goToLogin(true);
				});
			} else {
				this.setFormFields();
			}
		 });
	}

	/*
	* register
	* 
	* Creates an account for an user
	*/
	register(formData) {
		if(formData.password != formData.password2){
			this.toast.error('Passwords doesn\'t match');
		} else {
			this.user.register(formData.username, formData.password, '#loginWrapper').then(result => {
				if((<any>result).error == 2){
					this.toast.error(formData.username + ' already in use');
				} else {
					this.toast.success('An e-mail has been send to '+formData.username);
					this.auth.goToLogin(true);
				}
			});
		}
	}


	/*
	* setFormFields
	* 
	* Set all default form fields
	*/
	setFormFields() {
		this.formFields.push({
			type: 'text',
			name: 'username',
			value: '',
			placeholder: '',
			label: 'E-mail',
			width: 100,
			required: true
		});
		this.formFields.push({
			type: 'password',
			name: 'password',
			value: '',
			placeholder: '',
			label: 'Password',
			width: 100,
			required: true
		});
		this.formFields.push({
			type: 'password',
			name: 'password2',
			value: '',
			placeholder: '',
			label: 'Password again',
			width: 100,
			required: true
		});
		this.formFields.push({
			type: 'submit',
			name: 'submit',
			value: 'Register',
			placeholder: '',
			label: '',
			width: 100,
			required: false,
			action: 'login'
		});
	}
}
