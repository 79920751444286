import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/models/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/models/customer/customer.service';
import { LoginService } from 'src/app/core/services/login/login.service';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/core/services/util/util.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';
import { officeService } from 'src/app/models/office/office.service';
import { TasksModel } from 'src/app/models/tasks/tasks.service';


@Component({
    selector: 'edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.less']
})
export class EditComponent implements OnInit {
    public workflowId: number;
    public moduleId: number;
    public companyName: string;
    public email: string;
    public kvk: string;
    public fiscaal: string;
    public customerNumber: string;
    public deadline: string;
    public deadlineBelasting: string;
    public financialYear: string;
    public financialYearPlus: any;
    public financialYearMinus: any;
    public editDeadline: boolean;
    public editDuration: boolean;

    constructor(
        public UserModel: UserModel,
        public CustomerService: CustomerService,
        public ToasterService: ToasterService,
        public officeService: officeService,
        public loginService: LoginService,
        public taskService: TasksModel,
        private route: ActivatedRoute,
        private router: Router,
        private util: UtilService,

    ) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.workflowId = params['workflowId'];
        });


        this.UserModel.getUserListEmployee().then(data => {
            this.CustomerService.getCustomersDetails(this.workflowId).then(data => {
                this.moduleId = data[0]['workflow_module_id'];
                this.financialYear = data[0]['financial_year'];
                this.financialYearMinus = <any>this.financialYear - 1;
                this.financialYearPlus = <any>this.financialYear + 1;

                if (this.officeService.modules.length == 0) {
                    this.officeService.getModulesForOffice(this.UserModel.userId, true).then(data => {
                        this.officeService.modules = data[0];
                        this.getCurrentModuleData();
                    })
                } else {
                    this.getCurrentModuleData();
                }

                this.CustomerService.getCustomerInfo(this.workflowId).then(data => {
                    this.companyName = data[0]['first_name'];
                    this.email = data[0]['email'];
                    this.kvk = data[0]['kvk_nummer'];
                    this.fiscaal = data[0]['fiscaalnummer'];
                    this.customerNumber = data[0]['klantnummer'];
                    this.deadline = data[0]['deadline_date'];
                    this.deadlineBelasting = data[0]['deadline_belastingdienst'];
                });
            });
        });

    }

    private getCurrentModuleData() {
        this.officeService.modules.forEach(mod => {
            if (mod['workflow_module_id'] == this.moduleId) {

                this.editDeadline = this.util.getBool(mod['edit_deadline']);
                this.editDuration = this.util.getBool(mod['edit_duration']);
            }
        });
    }

    userNameFormat(firstname, insertion, surname, userId, taskOrder) {
        var firstNameCheck = (firstname !== null) ? firstname : '';
        var insertionCheck = (insertion !== null) ? insertion : '';
        var surnameCheck = (surname !== null) ? surname : '';

        var name = firstNameCheck + " " + insertionCheck + " " + surnameCheck;

        return name;
    }

    checkForSelected(userId, taskOrder) {
        if (userId) {
            var $elem = document.getElementById(taskOrder + userId);

            if ($elem) {
                $elem.setAttribute('selected', 'selected');
            }
        }
    }

    updateField(event) {
        if (event.target.name == "companyName" && event.target.value == '') {
            this.ToasterService.error('Bedrijfsnaam mag niet leeg zijn');
        } else {
            this.CustomerService.updateCustomerField(this.workflowId, event.target.name, event.target.value).then(data => {
                this.ToasterService.success('Succesvol opgeslagen');
            });
        }
    }

    updateFieldEmployee(event, workflowTaskId) {
        event.srcElement.classList.remove('red')
        this.CustomerService.updateTaskEmployee(workflowTaskId, event.target.value);
    }

    updateDeadline(event, type) {
        this.route.params.subscribe(params => {
            this.workflowId = params['workflowId'];
        });
        this.CustomerService.updateDeadline(this.workflowId, event.target.value, type);
    }

    updateCustomerYear(event) {
        this.CustomerService.updateCustomerYear(this.workflowId, event.target.value).then(data => {
            this.ToasterService.success('Succesvol opgeslagen');
        });
    }

    focus(element) {
        document.getElementById(element).focus();
    }

    prevent(event) {
        if (event.ctrlKey === true) {
            event.preventDefault();
        }
    }

    deleteCustomer() {
        this.CustomerService.deleteCustomer(this.workflowId).then(data => {
            this.router.navigate(['myTasks']);
        });
    }

    public moveDivs(event) {
        var x = event.clientX;
        var y = event.clientY;
        //controle maken of je hem wel mag aanpassen.
        let _modal = this.util.getElement('#modal');
        this.util.removeClass(_modal, 'hidden');

        document.getElementById("modal").style.left = x - 300 + "px";
        document.getElementById("modal").style.top = y + 30 + "px";
    }

    public closeModal() {
        let _modal = this.util.getElement('#modal');
        this.util.addClass(_modal, 'hidden');
    }

    public editClick(task, event: Event) {
        const target: HTMLElement = event.target as HTMLElement;
        const taskBox: HTMLElement = target.parentElement.parentElement as HTMLElement

        (taskBox.querySelector(".task-info-name-edit") as HTMLElement).style.display = "block";

        (taskBox.querySelector(".workflow-task-name") as HTMLElement).style.display = "none";
        (taskBox.querySelector('.workflow-task-description') as HTMLElement).style.display = "none";
    }

    public editDone(task, event: Event) {
        const target: HTMLElement = event.target as HTMLElement;
        const taskBox: HTMLElement = target.parentElement.parentElement as HTMLElement

        (taskBox.querySelector(".task-info-name-edit") as HTMLElement).style.display = "none";

        (taskBox.querySelector(".workflow-task-name") as HTMLElement).style.display = "block";
        (taskBox.querySelector('.workflow-task-description') as HTMLElement).style.display = "block";

        this.CustomerService.updateCustomerTaskName(task.user_id_office, task.workflow_module_id, task.task_order, task.task_name, task.task_description)
    }

    /**
     * updateTaskDuration
     *
     * Function thats called on change of duration field to change the duration of specific task id
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param workflowId required
     * @param taskId required
     * @param id required
     */
    public updateTaskDuration(workflowId, taskId, duration) {
        this.CustomerService.updateTaskDuration(workflowId, taskId, duration).then(result => {
            if (result) {
                this.ToasterService.success('Looptijd en deadlines bijgwerkt.');
            } else {
                this.ToasterService.error('Er is iets foutgegaan bij het aanpassen van de looptijd.');
            }
        })
    }
}
