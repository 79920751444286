import { ElementRef } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/core/services/ajax/ajax.service';
import { UtilService } from 'src/app/core/services/util/util.service';
import { TasksModel } from 'src/app/models/tasks/tasks.service';

@Component({
  selector: 'task-repeat-popup',
  templateUrl: './task-repeat-popup.component.html',
  styleUrls: ['./task-repeat-popup.component.less']
})
export class TaskRepeatPopupComponent implements OnInit {
  @Input() repeatingTaskInfo: any | undefined
  @Input('task') task: any | undefined;

  public isOpen = false;
  constructor(public router: Router, public taskModel: TasksModel, public elementRef: ElementRef) { }

  async ngOnInit() {
    
  }

  public async deleteRepeatingTask() {
    this.isOpen = false;
    await this.taskModel.deleteRepeatingTask(this.repeatingTaskInfo.repeat_task_id);
    this.router.navigate(['/']);
  }
}
