import { Component, OnInit } from '@angular/core';
import { TasksModel } from "../../../../models/tasks/tasks.service"
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/services/login/login.service';
import { UtilService } from 'src/app/core/services/util/util.service';

@Component({
  selector: 'overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.less']
})
export class OverviewComponent implements OnInit {
  public activeTask: any;
  public deadlineStatus = "Deadline verstreken";
  public taskArray: any;
  private taskId: any;
  private selectedModal: any;

constructor(
    public TasksModel: TasksModel,
    public loginService: LoginService,
	private router: Router,
	private util: UtilService
  ) {

  }

    ngOnInit() {
        if(this.loginService.invoice){
            this.TasksModel.getAllInvoice();
        }else{
            this.router.navigate(['myTasks']);
        }
    }

    /**
    * checkDeadline
    *
    * Check witch color soud be donse
    *
    * @author      	Romain van Maanen <romain@safira.nl>
    * @lastEdit     21-11-2018 10:33
    * @lastEditBy   Romain van Maanen <romain@safira.nl>
    */
    public checkDeadline(stringDate, task){
        const currentDate = new Date().toJSON().slice(0, 10);
        if(currentDate == stringDate){
            this.deadlineStatus = "Deze week";
            return 'green';
        } else if(currentDate < stringDate){
            this.deadlineStatus = "Deze week";
            return 'green';
        }else{
            this.deadlineStatus = "Deadline verstreken";
            return 'red';
        }
    }

    /**
    * convertDate
    *
    * convert date to wanted format
    *
    * @author      	Romain van Maanen <romain@safira.nl>
    * @lastEdit     21-11-2018 10:33
    * @lastEditBy   Romain van Maanen <romain@safira.nl>
    */
   	public convertDate(stringDate){
        const date = new Date(stringDate);
        const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni","Juli", "Augustus", "September", "Oktober", "November", "December"];

        var day = date.getDate();
        var month = monthNames[date.getMonth()];
        var year = date.getFullYear();

        var yeardegits = year.toString().slice(-2);

        var format = day + " " + month + " '" + yeardegits;
        return format;
    }

    public moveDivs(event, task){
        this.taskId = task;

        if (this.selectedModal) {
            this.util.addClass(this.selectedModal, 'hidden');
            this.selectedModal = null;
        }

        this.selectedModal = event.target.nextSibling;

        let modal = event.target.nextSibling;
		this.util.removeClass(modal, 'hidden');

        // document.getElementById("modal").style.left=x - 300 + "px";
        // document.getElementById("modal").style.top=y + 30 +"px";
    }

    public compleetInvoice(){
        this.TasksModel.compleetInvoice(this.taskId).then(data => {
			let _modal = this.util.getElement('#modal');
			this.util.addClass(_modal, 'hidden');
            this.TasksModel.getAllInvoice();
        });
    }

    public closeModal(){
        this.util.addClass(this.selectedModal, 'hidden');
        this.selectedModal = null;
        // let _modal = this.util.getElement('#modal');
		// this.util.addClass(_modal, 'hidden');
    }

    userName(firstName, insertion, lastName){
        var firstName = ( firstName != null) ? firstName : '';
        var insertion = ( insertion != null) ? insertion : '';
        var lastName = ( lastName != null) ? lastName : '';
        return firstName + " " + insertion + " " + lastName;
    }
}
