import { OnInit, Component, Input, forwardRef } from "@angular/core";
import { WorkflowModuleService } from "src/app/models/workflow-module/workflow-module.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UserModel } from "src/app/models/user/user.service";
import { ConfirmboxService } from "src/app/core/services/confirmbox/confirmbox.service";
import { UtilService } from "src/app/core/services/util/util.service";

@Component({
    selector: 'module-settings-detail',
    templateUrl: './module-settings-detail.component.html',
    styleUrls: ['./module-settings-detail.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ModuleSettingsDetailComponent),
            multi: true
        },
    ]
})



export class ModuleSettingsDetailComponent implements OnInit, ControlValueAccessor {

    @Input('module') module: any;

    @Input('userId') userId: number;

    constructor(public ConfirmboxService: ConfirmboxService, public workflowService: WorkflowModuleService, private userService: UserModel, private util: UtilService) { }

    ngOnInit() {
        if(this.util.empty(this.workflowService.periodList)) {
            this.workflowService.getPeriods().then(result => {
                this.workflowService.periodList = result;
            })
        }
    }

    writeValue(module) {
        this.module = module;

    }
    registerOnChange(fn) {
    }

	registerOnTouched(fn) {
	}
    setDisabledState?(isDisabled: boolean): void {
        throw new Error("Method not implemented.");
    }


    closePopup() {
        this.module = null;
    }

    public updatePeriod(event, oldvalue) {
        // if its a master, it can only be office, else it should be an manager
        var periodId = event.target.value;
        event.target.value = oldvalue;
        this.module.current_period_id = oldvalue;
        this.ConfirmboxService.confirm(
            'Weet u het zeker?',
            'Door deze wijziging worden de niet voltooide taken verwijderd en nieuwe taken aangemaakt onder de nieuwe periode voor dit ' + (this.userService.isMaster == true ? 'kantoor' : 'bedrijf') + '.',
            () => {
                event.target.value = periodId;
                this.module.current_period_id = periodId;
                if(this.userService.isMaster) {
                    this.workflowService.updatePeriodOnOffice(this.userId, this.module['workflow_module_id'], periodId);
                }
                if(this.userService.isManager) {
                    this.workflowService.updatePeriodOnCompany(this.userId, this.module['workflow_module_id'], periodId);
                }
            },
            () => void(0)
        );
    }
}
