import { Component } from '@angular/core';
import { ConfirmboxService } from '../../services/confirmbox/confirmbox.service';

@Component({
  selector: 'confirmbox',
  templateUrl: './confirmbox.component.html',
  styleUrls: ['./confirmbox.component.less']
})
export class ConfirmboxComponent {
	
  	constructor(public confirmboxService: ConfirmboxService) { }

}
