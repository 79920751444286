import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { LoginService } from 'src/app/core/services/login/login.service';
import { UserModel } from 'src/app/models/user/user.service';

const ULOPEN = 'ul-open';
const ULCLOSE = 'ul-close';
const MENUOPEN = 'menu-open';
const MENUCLOSE = 'menu-close';
const CARETRIGHT = 'fa-caret-right';
const CARETDOWN = 'fa-caret-down';
const ISACTIVE = 'is-active';
const MINPCSIZE = 1024;

@Component({
	selector: 'top-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.less'],
})
export class MenuComponent implements OnInit {
	public username = "";
	public insertion = "";
	public surname = "";
    public href: string;
    public manager = false;
    public invoice = false;
    public master = false;

	constructor(
        public loginService: LoginService,
        public userService: UserModel
	) {
        this.ngOnInit();
	}

	ngOnInit() {
        this.checkRoute();
	}
	/**
	  * checkRoute
	  *
	  * Check what our route is
	  *
	  * @author       Jurre Kon <jurre@safira.nl>
	  * @lastEdit     12-07-2018 12:10
	  * @lastEditBy   Jurre Kon <jurre@safira.nl>
	  */
	public checkRoute(): void {
		this.href = window.location.href.split('/')[3];
	}

}
