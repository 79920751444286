import { Injectable } from '@angular/core';
import { AjaxService } from 'src/app/core/services/ajax/ajax.service';


@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    public timeout: any;
    public customers: any;
    public customersDetail: any;
    public customersInfo: any;
    public customersName: any;
    public customersYear: any;
    public customersDeadline: any;
    public customersDeadlineBelasting: any;
    public customersWorkflows: any;
    public result: any;
    public searchString: string;
    public customerList: any;

    constructor(
        private ajax: AjaxService
    ) { }

    /**
    * searchCustomers
    *
    * Search for customers
    *
    * @author       Jurre Kon <jurre@safira.nl>
    * @lastEdit     26-11-2018 15:24
    * @lastEditBy   Jurre Kon <jurre@safira.nl>
    */
    public searchCustomers() {
        window.clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            let promise = new Promise((resolve, reject) => {
                let value = this.searchString;
                if (value === '') {
                    value = 'NULL';
                }

                this.ajax.get('customer/searchCustomers/' + value, '.header-container').then(
                    res => { // Success
                        this.customers = res;
                        this.customers = Object.values(this.customers);
                        this.sortFinancialYears();
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
            });
        }, 200);
    }

    /**
    * getAllCustomers
    *
    * List of all customers
    *
    * @author       Arjan Koemans <arjan@safira.nl>
    * @lastEdit     09-01-2019 14:15
    * @lastEditBy   Arjan Koemans <arjan@safira.nl>
    */
    public getAllCustomers($moduleId = 1) {
        let promise: any = new Promise((resolve, reject) => {
            let data = { 'userId': null, 'moduleId': $moduleId };
            this.ajax.post('customer/allCustomers', data, '.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => { // Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    * getCustomersDetails
    *
    * get workflow
    *
    * @author       Romain van Maanen <romain@safira.nl>
    * @lastEdit     26-11-2018 15:24
    * @lastEditBy   Romain van Maanen <romain@safira.nl>
    */
    public getCustomersDetails(id) {
        let promise = new Promise((resolve, reject) => {
            this.ajax.get('customer/getCustomersDetails/' + id, '.main-container').then(
                res => { // Success
                    this.customersDetail = res;
                    this.customersDeadline = res[0]['workflow_deadline'];
                    this.customersDeadlineBelasting = res[0]['deadline_belastingdienst'];
                    this.customersName = res[0]['company_name'];
                    this.customersYear = res[0]['financial_year'];
                    this.customersWorkflows = res[0]['customersWorkflows'];
                    resolve(res);
                },
                msg => { // Error
                    reject(msg);
                }
            );
        });

        return promise;
    }

    /**
*
* create new finencial year
*
* @author			Romain van Maanen <romain@safira.nl>
*/
    public createNewYear(workflowId) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'workflowId': workflowId };
            this.ajax.post('customer/createNewYear', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * updateCustomerYear
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			11-02-2019 08:54
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateCustomerYear(workflowId, value) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'workflowId': workflowId, 'value': value };
            this.ajax.post('customer/updateCustomerYear', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    * getCustomersDetails
    *
    * get workflow
    *
    * @author       Romain van Maanen <romain@safira.nl>
    * @lastEdit     26-11-2018 15:24
    * @lastEditBy   Romain van Maanen <romain@safira.nl>
    */
    public SetTaksStatus(taksStatus, taskId) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'taskStatus': taksStatus, 'taskId': taskId };
            this.ajax.post('customer/setTaskStatus', data, '.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => { // Error
                    reject(msg);
                }
            );
        });

        return promise;
    }

    /**
    * checkTaskFlow
    *
    * Check if u can change this task status
    *
    * @author       Romain van Maanen <romain@safira.nl>
    * @lastEdit     26-11-2018 15:24
    * @lastEditBy   Romain van Maanen <romain@safira.nl>
    */
    public checkTaskFlow(workflowTaskId, taskOrder) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'workflowTaskId': workflowTaskId, 'taskOrder': taskOrder };
            this.ajax.post('customer/checkTaskFlow', data, '.main-container').then(
                res => { // Success
                    this.result = res;
                    resolve(res);
                },
                msg => { // Error
                    reject(msg);
                }
            );
        });

        return promise;
    }

    /**
    *
    * Get Customer Indo
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			11-12-2018 10:49
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public getCustomerInfo(workflowId) {
        let promise = new Promise((resolve, reject) => {
            this.ajax.get('customer/getCustomerInfo/' + workflowId, '.main-container').then(
                res => { // Success
                    this.customersInfo = res;
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * Update Customers field
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			14-12-2018 09:43
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateCustomerField(id, fieldName, value) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'id': id, 'fieldName': fieldName, 'value': value };
            this.ajax.post('customer/updateCustomerField', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * update task empoyee
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			14-12-2018 14:06
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateTaskEmployee(id, value) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'id': id, 'value': value };
            this.ajax.post('customer/updateTaskEmployee', data, '.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * ipdate deadline
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			18-12-2018 14:47
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateDeadline(workflow_id, value, type) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'workflow_id': workflow_id, 'value': value, 'type': type };
            this.ajax.post('customer/updateDeadline', data, '.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * deleteCustomer
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			05-02-2019 07:44
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public deleteCustomer(workflowId) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'workflowId': workflowId };
            this.ajax.post('customer/deleteCustomer', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    public getModulesForCustomer(userId) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'userId': userId };
            this.ajax.post('customer/getModules', data).then(
                res => {
                    resolve(res)
                },
                msg => {
                    reject(msg)
                }
            );
        });
        return promise;
    }


    /**
     * turnOnModule
     *
     * turns on a module for a customer
     *
     * @param userId number required
     * @param moduleId number required
     */
    public turnOnModule(userId, moduleId) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'companyId': userId, 'moduleId': moduleId };
            this.ajax.post('user/office/createWorkflowByCompanyId', data, '.main-container').then(
                res => {
                    resolve(res)
                },
                msg => {
                    reject(msg)
                }
            );
        });
        return promise;
    }

    /**
     * turnOffModule
     *
     * turns off a module for a customer
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param userId number required
     * @param moduleId number required
     */
    public turnOffModule(userId, moduleId) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'companyId': userId, 'moduleId': moduleId };
            this.ajax.post('user/office/removeWorkflowByCompanyId', data, '.main-container').then(
                res => {
                    resolve(res)
                },
                msg => {
                    reject(msg)
                }
            );
        });
        return promise;
    }

    /**
     * updateTaskDuration
     *
     * Function to call the endpoint to update task durations
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param workflowId required
     * @param taskId required
     * @param duration required
     */
    public updateTaskDuration(workflowId, taskId, duration) {
        let promise = new Promise((resolve, reject) => {
            let data = { 'workflowId': workflowId, 'taskId': taskId, 'duration': duration };
            this.ajax.post('customer/updateTaskDuration', data, '.main-container').then(
                res => {
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
        return promise;
    }

    /**
     * getCustomerList
     *
     * calls endpoint to get list of customers for this office
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param  none
     */
    public getCustomerList() {
        let promise = new Promise((resolve, reject) => {
            this.ajax.get('customer/getCustomerList').then(
                res => {
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
        return promise;
    }

    /**
     * 
     * updates a taskname for a specific office
     * 
     * @author Bas Hoppenbrouwers
     * @param officeId 
     * @param workflowModuleId 
     * @param taskOrder 
     * @param taskName 
     * @returns 
     */
    public updateCustomerTaskName(officeId: string, workflowModuleId: string, taskOrder: string, taskName: string, taskDescription: string) {
        return this.ajax.post('customer/updateTasknameForOffice', {
            officeId,
            workflowModuleId,
            taskOrder,
            taskName,
            taskDescription
        });
    }

    /**
     * sortFinancialYears
     *
     * sorts the financial years column for customers
     *
     * @author  Eric van Doorn <eric@safira.nl>
     */
    private sortFinancialYears() {
        this.customers.forEach(customer => {
            customer.financial_year.sort((a, b) => a.workflow_id < b.workflow_id ? -1 : a.workflow_id > b.workflow_id ? 1 : 0)
        });
    }
}
