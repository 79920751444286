import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';

import { ResponsiveFramework } from './core/services/responsive-framework/responsive-framework.service';
import { AjaxService } from './core/services/ajax/ajax.service';

import { UserModule } from './modules/user/user.module';
import { TasksModule } from './modules/tasks/tasks.module';
import { CustomerModule } from './modules/customer/customer.module';
import { InvoiceModule } from './modules/invoice/invoice.module';
import { OfficeModule } from './modules/office/office.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';

//register NL locale
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { PrintModalComponent } from './core/components/printmodal/printmodal.component';
import { BreadcrumbModule } from './core/components/breadcrumb/breadcrumb.module';
import { ConfirmboxComponent } from './core/components/confirmbox/confirmbox.component';
import { FormsModule } from '@angular/forms';
import { CardComponent } from './core/components/card/card.component';
import { SafeHtmlPipe } from './core/pipe/safe-html.pipe';
// import { ImageCropModule } from './core/components/image-crop/image-crop.module';
// import { MapsComponent } from './core/components/maps/maps.component';
import { FormModule } from './core/components/form/form.module';
import { RedactorModule } from './core/directives/redactor/redactor.module';
import { OverviewComponent } from './core/components/overview/overview.component';
import { TabModule } from './core/directives/tab/tab.module';
import { TabScrollModule } from './core/directives/tab-scroll/tab-scroll.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenuComponent } from './core/components/menu/menu.component';
import { textareaDirective } from './core/components/textarea/textarea.directive';
import { DndDirective } from './core/directives/dnd/dnd.directive';
import { WorkflowModulesModule } from './modules/workflow-modules/workflow-modules.module';

registerLocaleData(localeNL);

// Routers
const appRoutes: Routes = [];

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		CardComponent,
		SafeHtmlPipe,
		// MapsComponent,
		OverviewComponent,
        PrintModalComponent,
		textareaDirective,
		DndDirective,
	],
	imports: [
		BrowserModule,
		HttpModule,
		RouterModule.forRoot(appRoutes),
        UserModule,
        OfficeModule,
		TasksModule,
		BreadcrumbModule,
		FormsModule, //required for [(ngModel)]
		// ImageCropModule,
		FormModule, // Custom Safira form module
		RedactorModule,
		TabModule,
		TabScrollModule,
		CustomerModule,
		AngularFontAwesomeModule,
        InvoiceModule,
        WorkflowModulesModule,
	],
	providers: [ResponsiveFramework, AjaxService, PrintModalComponent,ConfirmboxComponent, { provide: LOCALE_ID, useValue: 'nl-AW' }],
	bootstrap: [AppComponent]
})
export class AppModule {

}
