import { Component, Input, ChangeDetectorRef, ViewContainerRef, HostListener, forwardRef, ViewEncapsulation, ContentChild, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControlName } from '../../../../../node_modules/@angular/forms';

@Component({
  selector: 'sTextarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
	  multi: true
	}
  ],
  encapsulation: ViewEncapsulation.None
})
export class TextareaComponent implements ControlValueAccessor  {

	//holds the value
	@Input('value') _value = '';

	//holds the name of the input
	@Input('label') label: string;

	//holds the name of the input
	@Input('name') name: string;

	//holds the readonly state
	@Input('required') required = false;

	//holds the disabled state
	@Input('disabled') disabled = false;

	//holds the readonly state
	@Input('readonly') readonly = false;
	
	//holds the placeholder
	@Input('placeholder') placeholder: string = '';

	//holds the maxlength
	@Input('maxLength') maxLength;

	//holds the name of the input
	@Input('change') _functionNameKeyUp: any;

	onChange: any = () => {};
	onTouched: any = () => {};


	//holds the value
	valueLength:number = 0;
	
	/**
    * constructor
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	constructor(private viewContainerRef: ViewContainerRef, private cdRef:ChangeDetectorRef) { 

	}

	/**
    * update
    * 
    * Updates the value of the input
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	update(event) {
		this.value = event.target.value;
		this.valueLength = this.value.length;
	}

	/**
    * getter
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	get value() {
		return this._value;
	}
	
	/**
    * setter
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	set value(val) {
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}
	
	/**
    * registerOnChange
    * 
    * Registers the onChange function
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	registerOnChange(fn) {
		this.onChange = fn;
	}
	
	/**
    * registerOnTouched
    * 
    * Registers the onTouch function
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	registerOnTouched(fn) { 
		this.onTouched = fn;
	}
	
	/**
    * writeValue
    * 
    * Writes the value
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	writeValue(value) {
		this.value = value;
	}

	/**
    * HostListener keyup
    * 
    * update the input on keyup
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	@HostListener('keyup', ['$event']) keyup(event: KeyboardEvent) {
		this.update(event);

		if(typeof this._functionNameKeyUp !== "undefined") this.getParentComponent()[this._functionNameKeyUp](event);
	}



	getParentComponent(){
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
	}

  



}
