import { Component, OnInit, ElementRef, Input } from '@angular/core';

@Component({
	selector: 'sPopup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.less']
})
export class PopupComponent implements OnInit {

	@Input('sClass')          additionalClass: string;

	@Input('closeButton') closeButton: string;

	public showClose: boolean;

	constructor(private erf: ElementRef) { }

	ngOnInit() {
		if(this.closeButton === "true"){
			this.showClose = true;
		} else {
			this.showClose = false;
		}
	}

	public closePopup(){
		this.erf.nativeElement.classList.add('hide');
		document.body.style.height 			= '';
		document.body.style.overflow 		= '';
	}

}