import { Directive, Input, Output, EventEmitter, HostListener,ElementRef   } from '@angular/core';

const dragSrcEl = {};

@Directive({
	selector: '[dnd]'
})
export class DndDirective {
	@Output() 	callback = new EventEmitter<any[]>();
	@Input() 	dndActive: number = 1;
	constructor(el?: ElementRef) {
		el.nativeElement.setAttribute('draggable','true');
	}

	@HostListener('dragstart', ['$event'])
	onDragStart(event) {
		this.handleDragStart(event);
	}
	@HostListener('dragenter', ['$event'])
	onDragEnter(event) {
		this.handleDragEnter(event);
	}
	@HostListener('dragover', ['$event'])
	onDragOver(event) {
		this.handleDragOver(event);
	}
	@HostListener('drag', ['$event'])
	onDrag(event) {
		this.handleDrag(event);
	}
	@HostListener('dragend', ['$event'])
	onDragDragend(event) {
		this.handleDragEnd(event);
	}

	public handleDrag(e) {
	}

	public handleDragStart(e) {
		if(this.dndActive){
			//set class to wrapper
			var wraptarget = (e.target.nodeName == '#text' ? e.target.parentNode : e.target);
			wraptarget.closest('.dnd-outer-container').classList.add('dnd-dragging-active');

			// Save the target in a variable so we can access it later
			if(wraptarget.classList.contains("dnd-item")) {
				(<any>dragSrcEl) = e.target;
			} else {
				(<any>dragSrcEl) = e.target.closest('.dnd-item');
			}
	
			(<any>dragSrcEl).classList.add('dragging-element');

			// Here we declared what we are allowed to do with to object and which data we send with it
			e.dataTransfer.effectAllowed = 'move';
			e.dataTransfer.setData('html', e.target.outerHTML);		
		}
	}

	public handleDragOver(e) {
		if (this.dndActive) {
			if (e.preventDefault) {
				// Necessary. Allows us to drop.
				e.preventDefault();
			}

			// See the section on the DataTransfer object.
			e.dataTransfer.dropEffect = 'move';
			return false;
		}
	}

	public handleDragEnter(e) {
		// insert the element before the current element
		if (this.dndActive) {

			//fix for FireFox. When DnD on text it will not have a
			//valid HTML target so .closest() can't be called. If this
			//happens, use the parentElement.
			/*if(e.target.nodeName == '#text') {
				if (this.isbefore(dragSrcEl, e.target)) {
					e.target.parentNode.closest('.dnd-outer-container').insertBefore(dragSrcEl, e.target.parentNode.closest('.dnd-item'));
				} else {
					e.target.parentNode.closest('.dnd-outer-container').insertBefore(dragSrcEl, e.target.parentNode.closest('.dnd-item').nextElementSibling);
				}
			} else {
				if (this.isbefore(dragSrcEl, e.target)) {
					e.target.closest('.dnd-outer-container').insertBefore(dragSrcEl, e.target.closest('.dnd-item'));
				} else {
					e.target.closest('.dnd-outer-container').insertBefore(dragSrcEl, e.target.closest('.dnd-item').nextElementSibling);
				}
			}*/

			if (this.isbefore(dragSrcEl, e.target)) {
				e.target.closest('.dnd-outer-container').insertBefore(dragSrcEl, e.target.closest('.dnd-item'));
			} else {
				e.target.closest('.dnd-outer-container').insertBefore(dragSrcEl, e.target.closest('.dnd-item').nextElementSibling);
			}
		}
	}

	public handleDragEnd(e) {
		var newArray = [];
		if (this.dndActive) {
			// remove all style changes we made while dragging
			e.target.removeAttribute('style');

			e.target.classList.remove('dragging-element');

			//set class to wrapper
			var wraptarget = (e.target.nodeName == '#text' ? e.target.parentNode : e.target);
			wraptarget.closest('.dnd-outer-container').classList.remove('dnd-dragging-active');

			// update the order of the items
			var items = document.getElementsByClassName('dnd-item');
			
			// Build new array with item ID and order number
			for (var i = 0; i < items.length; i++) {
				newArray.push(items[i].getAttribute('dnd-id'));
			}

			this.callback.next(newArray);
		}
	}

	// here we check if the item we're holding should be inserted before or after the item we are hovering above
	public isbefore(a, b) {
		if(typeof a === 'undefined' || a == null || typeof b === 'undefined' || b == null){
			return false;
		}
		if (a.parentNode == b.parentNode) {
			for (var cur = a; cur; cur = cur.previousSibling) {
				if (cur === b) {
					return true;
				}
			}
		}
		return false;
	}


}
