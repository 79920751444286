import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sRadio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class RadioComponent implements OnInit {
  
  @Input('label') label: string;

  @Input('radioButtons') radioButtons: any;
  
  constructor() { 
   
  }

  ngOnInit() {

  }

}
