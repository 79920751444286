import { Component } from '@angular/core';
import { UserModel } from '../../../../models/user/user.service';
import { AuthorisationService } from '../../../../core/services/authorisation/authorisation.service';
import { ToasterService } from '../../../../core/services/toaster/toaster.service';
import { LoginService } from 'src/app/core/services/login/login.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router'

@Component({
	selector: 'forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent {
	formFields: any = [];
	withHash: boolean = false; 
    hash: string = '';
    private sendMail = false;

	constructor(
		private user: UserModel,
		private auth: AuthorisationService,
        private toast:ToasterService,
        public loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router
	) {
		
		this.route.params.subscribe(params => {
			if(typeof params['hash'] != 'undefined'){
				this.withHash = true;
				this.hash = params['hash'];
			} else {

			}
		 });

		
	}

	/*
	* forgotPassword
	* 
	* Will send the ajax call for forget password
	*/
	forgotPassword(formData) {
		if(this.withHash){
			if(formData.password != formData.password2){
				this.toast.error('Password doesn\'t match');
			} else {
				this.user.saveForgotPassword(formData.password, this.hash,  '#loginWrapper').then(result => {
					this.toast.success('You\'re password has been set');
					this.auth.goToLogin(true);
				});
			}
		} else {
			this.user.forgotPassword(formData.username, '#loginWrapper').then(result => {
				this.toast.success('An e-mail has been send to '+formData.username);
				this.auth.goToLogin(true);
			});
		}
	}

	/*
	* setFormFields
	* 
	* Set all default form fields
	*/
    
        /**
	  * Start the reset password protocool
	  *
	  * @author       Romain <romain@safira.nl>
	  * @lastEdit     10-1-2019 08:45
	  * @lastEditBy   Romain van Maanen <romain@safira.nl>
	  */
    generateRestPasswordPage(){
        this.loginService.generateRestPasswordPage().then(data => {
            this.sendMail = true;
        });
    }

    resetPassword(){
        this.loginService.resetPasswordFromEmail(this.hash).then(data => {
            this.router.navigate(['login']);
        });
    }
}
