import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { takenRouting } from './tasks.routes';
import { AllTasksComponent } from './view/all-tasks/all-tasks.component';
import { MyTasksComponent } from './view/my-tasks/my-tasks.component';

import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { MenuModule } from 'src/app/core/components/menu/menu.module';
import { FooterModule } from 'src/app/core/components/footer/footer.module';
import { PopupModule } from 'src/app/core/components/popup/popup.module';
import { TaskRepeatPopupComponent } from './view/task-dashboard/task-repeat-popup/task-repeat-popup.component';
import { SingleTaskCreateComponent } from './view/my-tasks/create/create.component';
import { taskDashboardComponent } from './view/task-dashboard/task-dashboard.component';
import { taskDashboardListComponent } from './view/task-dashboard/task-dashboard-list/task-dashboard-list.component';


@NgModule({
  imports: [
    BrowserModule,
    takenRouting,
    FormsModule,
    FormModule,
    MenuModule,
    FooterModule,
    PopupModule
  ],
  declarations: [
    AllTasksComponent,
    MyTasksComponent,
    SingleTaskCreateComponent,
    taskDashboardComponent,
    taskDashboardListComponent,
    TaskRepeatPopupComponent
  ],
  providers: [

  ]
})
export class TasksModule { }
