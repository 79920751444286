import { Injectable, ApplicationRef  } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ResponsiveFramework{
  public grade: string;
  gradeChange: Subject<string> = new Subject<string>();

  constructor(
    private ApplicationRef : ApplicationRef ,
  ) { 
    this.getGrade();
  }

 
  onResize(){
    this.getGrade();
  }


  /*
  * getGrade()
  * 
  * Get the current grade and saves it in `grade` 
  */
  getGrade(){
    this.gradeChange.subscribe((value) => {
      if(value != this.grade){
        this.grade = value;
      }
    });

    this.gradeChange.next((window.innerWidth > 900 ? 'desktop' : 'mobile'));
  }

}
