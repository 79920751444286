import { OnInit, Component, Input, ViewContainerRef, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { WorkflowModuleService } from "src/app/models/workflow-module/workflow-module.service";
import { UtilService } from "src/app/core/services/util/util.service";
import { ConfirmboxService } from "src/app/core/services/confirmbox/confirmbox.service";
import { UserModel } from "src/app/models/user/user.service";
import { ToasterService } from "src/app/core/services/toaster/toaster.service";

@Component({
    selector: 'module-settings-popup',
    templateUrl: './module-settings-popup.component.html',
    styleUrls: ['./module-settings-popup.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ModuleSettingsPopupComponent),
            multi: true
        },
    ]
})

export class ModuleSettingsPopupComponent implements OnInit, ControlValueAccessor {
    // holds an array of objects of the modules that should be showed on the oage
    @Input('modules') modules: Array<object>;

    // holds the userId of the user the popup whas opened for
    @Input('userId') userId: number;

    // holds the username that will be showed in the popup header
    @Input('username') username: string;

    // Function that will be called in the parent component to turn on the module, inputs the module id and user id
    @Input('turnOnFunction') _turnOnFunction: any;

    // function that will be called in the parent component to turn off the module, inputs the module id and user id
    @Input('turnOffFunction') _turnOffFunction: any;

    public currentModule: any;

    constructor(
        private ConfirmboxService: ConfirmboxService,
        private viewContainerRef: ViewContainerRef,
        private toaster: ToasterService,
        public workflowService: WorkflowModuleService,
        public util: UtilService,
        public userService: UserModel
    ) {}

    ngOnInit() {
        if(this.util.empty(this.workflowService.periodList) && this.userService.isManager) {
            this.workflowService.getPeriods().then(result => {
                this.workflowService.periodList = result;
            })
        }
    }

    writeValue(value) {
        this.modules = value;
        if(this.modules) this.setBooleans();
    }

    registerOnChange(fn) {
    }

	registerOnTouched(fn) {
	}

    // handles the toggle click
    public setToggle(event, i) {
        var newValue = event.target.checked;
        event.target.checked = (newValue == true ? false : true);
        let found = false;
        if(this.modules[i]['system_name'] != 'bookyear_module' && newValue) {
            this.modules.forEach(module => {
                if(module['system_name'] == 'bookyear_module') {
                    if(module['enabled'] != '1') {
                        this.toaster.error('U kunt geen andere modules aanzetten als de module voor het Boekjaar niet aan staat.');
                        found = true;
                    }
                }
            });
            if(found) return;
        }

        this.ConfirmboxService.confirm(
            'Weet u het zeker?',
            (newValue == true ?
                (this.userService.isMaster ? 'Dit kantoor krijgt toegang om zelf deze module per account aan of uit te zetten.' : 'De module zal worden aangezet voor deze klant en de taken zullen gegenereerd worden.') :
                (this.userService.isMaster ? 'Hierdoor worden alle taken verborgen en elk account van dit kantoor krijgt geen toegang meer tot deze module.' : 'De module zal worden uitgezet voor deze klant en de taken niet voltooide taken worden VERWIJDERD en de voltooide taken worden INACTIEF.')),
            () => {
                event.target.checked = newValue;
                this.modules[i]['enabled'] = event.target.checked;
                if(event.target.checked) {
                    if(typeof this._turnOnFunction !== "undefined") this.getParentComponent()[this._turnOnFunction](this.userId, this.modules[i]['workflow_module_id']);
                } else {
                    if(typeof this._turnOffFunction !== "undefined") this.getParentComponent()[this._turnOffFunction](this.userId, this.modules[i]['workflow_module_id']);
                }

            },
            () => void(0)
        );
    }


    /**
     * gets the parent component
     */
    private getParentComponent(){
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
    }

    /**
     * setBooleans
     *
     * replaces the strings with real booleans for the modules objects
     */
    private setBooleans() {
        this.modules.forEach(module => {
            switch (module['enabled']) {
                case 'true':
                case '1':
                    module['enabled'] = true;
                    break;
                case 'false':
                case '0':
                    module['enabled'] = false;
                    break;
                default:
                    module['enabled'] = false;
                    break;
            }
        });
    }

    public openSettings(module) {
        this.currentModule = null;

        // use timeout 0 to be sure the = null will be catched by the ng model/value accessor. else the settings popup cannot be re openend
        setTimeout(() => {
            this.currentModule = module;
        }, 0);
    }

    public closePopup() {
        this.modules = [];
    }
}
