import { Injectable } from '@angular/core';
import { AjaxService } from '../../core/services/ajax/ajax.service';

@Injectable({
	providedIn: 'root'
})


export class officeService {

    public modules: any = new Array();

    constructor(private ajax: AjaxService) {}

    public getModulesForOffice(userId, filterEnabled) {
        let promise = new Promise((resolve, reject) => {
            let data = {'userId': 'null', 'filterEnabled': filterEnabled};
            this.ajax.post('user/office/getModules', data).then(
                res => {
                    resolve(Array(res))
                },
                msg => {
                    reject(msg)
                }
            );
        });
        return promise;
    }

    public getModulesForMaster(userId) {
        let promise;
        let data = {'userId': userId}
        return promise = new Promise((resolve,reject) => {
            this.ajax.post('user/office/getAllModules', data).then(
                res => {
                    resolve(res);
                },
                msg => {
                    reject(msg);
                }
            );
        });
    }


    /**
     * turnOnModule
     *
     * turns on a module for a office
     *
     * @param userId number required
     * @param moduleId number required
     */
    public turnOnModule(userId, moduleId) {
        let promise = new Promise((resolve, reject) => {
            let data = {'companyId': userId, 'moduleId': moduleId, 'createWorkflow': true };
            this.ajax.post('user/office/createWorkflowByOfficeId', data, '.main-container').then(
                res => {
                    resolve(res)
                },
                msg => {
                    reject(msg)
                }
            );
        });
        return promise;
    }

    /**
     * turnOffModule
     *
     * turns off a module for a office
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param userId number required
     * @param moduleId number required
     */
    public turnOffModule(userId, moduleId) {
        let promise = new Promise((resolve, reject) => {
            let data = {'officeId': userId, 'moduleId': moduleId};
            this.ajax.post('user/office/removeWorkflowByOfficeId', data, '.main-container').then(
                res => {
                    resolve(res)
                },
                msg => {
                    reject(msg)
                }
            );
        });
        return promise;
    }

    /**
     * getOfficeModuleSettings
     *
     * Gets the current settings for a specific module for a specific office
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param officeId number required
     * @param moduleId number required
     */
    public getOfficeModuleSettings(officeId, moduleId) {
        let promise = new Promise((resolve, reject) => {
            let data = {userId: officeId, moduleId: moduleId};
            this.ajax.post('user/office/getOfficeModuleSettings', data).then(
                res => {
                    resolve(res)
                },
                msg => {
                    reject(msg)
                }
            );
        });
        return promise;
    }
}
