import { Component, OnInit, forwardRef } from '@angular/core';
import { officeService } from 'src/app/models/office/office.service';
import { TasksModel } from 'src/app/models/tasks/tasks.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserModel } from 'src/app/models/user/user.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/services/login/login.service';
import { MyTasksService } from 'src/app/models/myTasks/my-tasks.service';
import { AjaxService } from 'src/app/core/services/ajax/ajax.service';


@Component({
	selector: 'task-dashboard',
	templateUrl: './task-dashboard.component.html',
    styleUrls: ['./task-dashboard.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => taskDashboardComponent),
            multi: true
        },
    ]
})
export class taskDashboardComponent implements OnInit, ControlValueAccessor {
    writeValue(value): void {
        this.currentBigBlock = value;
    }
    registerOnChange(fn: any): void {
    }
    registerOnTouched(fn: any): void {
    }

    constructor(public officeService: officeService, public tasksModel: TasksModel, public myTasksService: MyTasksService, private userService: UserModel, private router: Router, private loginService: LoginService) {}

    public currentBigBlock = null;

    ngOnInit() {
    if(this.userService.isMaster) this.router.navigate(['/office/manage']);

        this.tasksModel.getUserTasks();

    }


    /**
     * updateCurBigBlock
     *
     * function to be called on expanding a list block
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param value
     */
    public updateCurBigBlock(value) {
        this.currentBigBlock = value;
    }
}
