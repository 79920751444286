import { Component, Input, ViewEncapsulation, forwardRef, HostListener, ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sSelect',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
	  multi: true
	}
  ],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements ControlValueAccessor  {
	
	//holds the value
	@Input('value') _value = '';

	//holds the name of the input
	@Input('label') label: string;

	//holds the name of the input
	@Input('name') name: string;

	//holds the name of the input
	@Input('change') _functionName: any;

	//holds the readonly state
	@Input('required') required = false;

	//holds the disabled state
	@Input('disabled') disabled = false;
	
	@Input() parent;

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor(private viewContainerRef: ViewContainerRef, private cdRef:ChangeDetectorRef) { }

	/**
    * writeValue
    * 
    * Writes the value
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	writeValue(value) {
		this._value = value;
	}

	/**
    * registerOnChange
    * 
    * Registers the onChange function
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	registerOnChange(fn) {
		this.onChange = fn;
	}
	
	/**
    * registerOnTouched
    * 
    * Registers the onTouch function
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	registerOnTouched(fn) { 
		this.onTouched = fn;
	}


	/**
    * HostListener keyup
    * 
    * update the input on keyup
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	@HostListener('change', ['$event']) keyup(event: KeyboardEvent) {
		this.update(event);
	}

	/**
    * update
    * 
    * Updates the value of the input
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	update(event) {
		this._value = event.target.value;
		if(typeof this._functionName !== "undefined") this.getParentComponent()[this._functionName](event);
	}

	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}

	getParentComponent(){
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
	}
	

}
