import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { UtilService } from '../../services/util/util.service';

const SCROLL_AMOUNT = 10;
const INTERVAL_TIME = 10;
const LIST_ITEM_ATTRIBUTE = 'tabNumber';
const DIRECTION_ATTRIBUTE = 'direction';
const LIST_CLASS = 'tabs-list';
const SCROLL_CLASS = 'scroll';

@Directive({
	selector: '[TabScroll]'
})
export class TabScrollDirective implements OnInit {
	@Input() direction: string;
	@Input() tabNumber: number;

	private intervalElement: any = {};
	private _tabsList: any = {};
	private _scrollButtons: any = {};

	private scrollCounter: number = 0;
	private scrollAmount: number = 10;
	private widthArrow: number = 0;

	private disableScroll: boolean = false;

	constructor(private util: UtilService) { }

	ngOnInit() {
		this._tabsList = this.util.getElements(`.${LIST_CLASS}`);
		this._scrollButtons = this.util.getElements(`.${SCROLL_CLASS}`);

		this.checkTabArrows();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event): void {
		this.checkTabArrows();
	}

	@HostListener('click', ['$event'])
	onClick(event): void {
		if(event.target.classList.contains(SCROLL_CLASS)){
			this.direction = event.target.getAttribute(DIRECTION_ATTRIBUTE);
			this.tabNumber = event.target.getAttribute(LIST_ITEM_ATTRIBUTE);
			this.widthArrow = event.target.offsetWidth;

			this.scroll(this.direction, this.tabNumber, this.widthArrow);
		} else if(event.target.parentElement.classList.contains(SCROLL_CLASS)){
			this.direction = event.target.parentElement.getAttribute(DIRECTION_ATTRIBUTE);
			this.tabNumber = event.target.parentElement.getAttribute(LIST_ITEM_ATTRIBUTE);
			this.widthArrow = event.target.parentElement.offsetWidth;

			this.scroll(this.direction, this.tabNumber, this.widthArrow);
		}
		
		this.checkTabArrows();
	}

	/**
    * scroll
    *
    * Makes the #tabs-list scroll to the left or right depending on the direction input
    *
    * @author       Jurre Kon <jurre@safira.nl>
    * @lastEdit     12-07-2018 12:10
    * @lastEditBy   Jurre Kon <jurre@safira.nl>
    */
	public scroll(direction: string, tabNumber: number, widthArrow: number): void {
		if (!this.disableScroll) {
			let widthTabBar 	= this.util.findParentWithClass(this._tabsList[tabNumber], 'tabs').offsetWidth;
			this.scrollAmount 	= widthTabBar - widthArrow * 2 - 100;
			this.scrollAmount 	= Math.ceil(this.scrollAmount / 10) * 10;
			this.disableScroll 	= true;
			this.scrollCounter 	= 0;

			this.intervalElement = setInterval(() => {
				this.scrollCounter += SCROLL_AMOUNT;

				if (direction === 'right') {
					this._tabsList[tabNumber].scrollLeft += SCROLL_AMOUNT;
				} else if (direction === 'left') {
					this._tabsList[tabNumber].scrollLeft -= SCROLL_AMOUNT;
				}

				this.checkTabArrows();

				if (this.scrollCounter === this.scrollAmount) {
					this.disableScroll = false;
					clearInterval(this.intervalElement);
				}

			}, INTERVAL_TIME);

			
		}
	}

	/**
    * checkTabArrows
    *
    * If there are no tabButtons, create them
	* Else we check the width and hide or show them accordingly
    *
    * @author       Jurre Kon <jurre@safira.nl>
    * @lastEdit     12-07-2018 12:10
    * @lastEditBy   Jurre Kon <jurre@safira.nl>
    */
	public checkTabArrows(): void {
		for (let i = 0; i < this._tabsList.length; i++) {
			if (this.util.getChild(this._tabsList[i].parentElement, SCROLL_CLASS) === undefined) {
				let newDivLeft = document.createElement('DIV');
				newDivLeft.className = `background-color-secondary ${SCROLL_CLASS} scroll-left`;
				newDivLeft.setAttribute(DIRECTION_ATTRIBUTE, 'left');
				newDivLeft.setAttribute(LIST_ITEM_ATTRIBUTE, i.toString());

				let newIconLeft = document.createElement('I');
				newIconLeft.className = 'fal fa-angle-left';
				newDivLeft.appendChild(newIconLeft);

				let newDivRight = document.createElement('DIV');
				newDivRight.className = `background-color-secondary ${SCROLL_CLASS} scroll-right`;
				newDivRight.setAttribute(DIRECTION_ATTRIBUTE, 'right');
				newDivRight.setAttribute(LIST_ITEM_ATTRIBUTE, i.toString());

				let newIconRight = document.createElement('I');
				newIconRight.className = 'fal fa-angle-right';
				newDivRight.appendChild(newIconRight);

				this._tabsList[i].parentElement.appendChild(newDivLeft);
				this._tabsList[i].parentElement.appendChild(newDivRight);
			}
		}

		for (let i = 0; i < this._tabsList.length; i++) {
			if (this._tabsList[i].offsetWidth < this._tabsList[i].scrollWidth) {
				for (let x = 0; x < this._scrollButtons.length; x++) {
					if (this.util.getAttribute(this._scrollButtons[x], LIST_ITEM_ATTRIBUTE) == i) {
						this._scrollButtons[x].style.display = 'block';
					}
				}
			} else if (this._tabsList[i].offsetWidth >= this._tabsList[i].scrollWidth) {
				for (let x = 0; x < this._scrollButtons.length; x++) {
					if (this.util.getAttribute(this._scrollButtons[x], LIST_ITEM_ATTRIBUTE) == i) {
						this._scrollButtons[x].style.display = 'none';
					}
				}
			}

			//no scroll, hide left arrow
			if(this._tabsList[i].scrollLeft == 0) {
				this.util.findChildrenWithClass( this._tabsList[i].parentElement, 'scroll-left')[0].style.display = 'none';
			}

			//no scroll, hide right arrow
			if ((this._tabsList[i].offsetWidth + this._tabsList[i].scrollLeft) >= this._tabsList[i].scrollWidth) {
				this.util.findChildrenWithClass( this._tabsList[i].parentElement, 'scroll-right')[0].style.display = 'none';
			}
			
		}
	}
}
