import { Component, OnInit, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'searchbox',
    templateUrl: './searchbox.component.html',
    styleUrls: ['./searchbox.component.less'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => searchboxComponent),
        multi: true
      }
    ],
    encapsulation: ViewEncapsulation.None

  })

export class searchboxComponent implements OnInit, ControlValueAccessor {
    writeValue(value) {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
    }

    onChange: any = () => {};
	onTouched: any = () => {};

    @Input('id') id: any;

    @Input('data') data: any;

    @Input('value') private _value: any;

    public showValue: any;

    public filteredData: any = null;

    ngOnInit() {
        this.filteredData = this.data;
    }

    	/**
    * getter
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
    get value() {
        return this._value;
    }

	/**
    * setter
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
    set value(val) {
        this._value = val;
        this.onChange(val);
    }

    public setCustomer(value: string, showValue: string) {
        this.writeValue(value);
        this.showValue = showValue;
    }

    public filterData(search: string) {
        this.filteredData = this.data.filter(function(customer) {
            let cust = customer.value.toUpperCase();
            let value = search.toUpperCase()
            return cust.includes(value);
        })
        this.writeValue(search);
    }
}
