import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { userRouting } from './user.routes';

import { LoginComponent } from './view/login/login.component';
import { RegisterComponent } from './view/register/register.component';
import { ForgotPasswordComponent } from './view/forgot-password/forgot-password.component';
import { ManageComponent } from './view/manage/manage.component';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { MenuModule } from 'src/app/core/components/menu/menu.module';
import { FooterModule } from 'src/app/core/components/footer/footer.module';
import { EditComponent } from './view/edit/edit.component';


@NgModule({
  imports: [
    BrowserModule,
	userRouting,
	FormsModule,
    FormModule,
    MenuModule,
    FooterModule
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ManageComponent,
    EditComponent
  ],
  providers: [
    
  ]
})
export class UserModule { }
