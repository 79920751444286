import { Routes, RouterModule } 	from '@angular/router';
import { ManageComponent } from './view/manage/manage.component';
import { EditComponent } from './view/edit/edit.component';
import { OfficeCreateComponent } from './view/create/create.component';

export const officeRoutes: Routes = [
    {
		path: 			'office/manage',
		component: 		ManageComponent
    },
    {
        path:           'office/create',
        component:      OfficeCreateComponent
    }
    // {
	// 	path: 			'office/edit/:userId',
	// 	component: 		EditComponent
	// }
];

export const officeRouting = RouterModule.forChild(officeRoutes);
