import { Component, Input } from '@angular/core';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.less']
})
export class BreadcrumbComponent {

  @Input() class: string;
  @Input() breadcrumb: any[];

  /**
    * crumbList
    * 
    * An array used for building the breadcrumb.
		*
		* @author 		Mark de Mol <mark@safira.nl>
		* @lastEdit		15-06-2018 13:00
		* @lastEditBy	Mark de Mol <mark@safira.nl>
		*/
  crumbList: Array<{label: string, link: string}>;

  /**
    * constructor
    * 
    * Add the amount given by the user to the failures. Also known
    * as manco.
		*
		* @author 		Mark de Mol <mark@safira.nl>
		* @lastEdit		15-06-2018 13:00
		* @lastEditBy	Mark de Mol <mark@safira.nl>
		*/
  constructor() { }


}
