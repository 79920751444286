import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ToasterService {
	toasterActive: boolean = false;
	activeTime: number = 3000;
	type: string = 'error';
	message:string = '';
	_timeout: any;

	constructor() { }

	/*
	*	success
	*
	*	Will show an success toaster
	*/
	success(message){
		this.type = 'success';
		this.activateMessage(message);
	}

	/*
	*	error
	*
	*	Will show an error toaster
	*/
	error(message){
		this.type = 'error';
		this.activateMessage(message);
	}

	/*
	*	activateMessage
	*
	*	will handle all logic to show a message
	*/
	activateMessage(message){
		this.message = message;
		this.toasterActive = true;
		this.toasterInterval();
	}

	/*
	*	toasterInterval
	*
	*	Will hide the toaster after the given time
	*/
	toasterInterval(){
		var tmpThis = this;
		clearTimeout(this._timeout);
		
		this._timeout = setTimeout(function(){
			tmpThis.toasterActive = false;
		}, this.activeTime);
	}
}
