import { Component, OnInit } from '@angular/core';
import { ResponsiveFramework } from './core/services/responsive-framework/responsive-framework.service';
import { Router } from '@angular/router';
import { ToasterService } from './core/services/toaster/toaster.service';
import { AuthorisationService } from './core/services/authorisation/authorisation.service';
import { BreadcrumbComponent } from './core/components/breadcrumb/breadcrumb.component';
import { PrintModalComponent } from './core/components/printmodal/printmodal.component';
import { ConfirmboxComponent } from './core/components/confirmbox/confirmbox.component';
import { AjaxService } from './core/services/ajax/ajax.service';
import { UserModel } from './models/user/user.service';
import { LoginService } from './core/services/login/login.service';
import { officeService } from './models/office/office.service';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

	constructor(
		public responsive: ResponsiveFramework,
		public router: Router,
		public toast: ToasterService,
		public auth: AuthorisationService,
		public breadcrumb: BreadcrumbComponent,
		public modal: PrintModalComponent,
        public officeService: officeService,
        public userModel: UserModel,
		public confirm: ConfirmboxComponent,
        private ajax: AjaxService,
        private loginService: LoginService,
	) {
		if (!this.auth.canAccess('core', 'index')) {
			//this.auth.goToLogin();
        }


	}

	ngOnInit() {
        if(this.auth.canAccess('','')) {
            this.loginService.getUserData();
        }
	}

}
