import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { LoginService } from 'src/app/core/services/login/login.service';
import { UtilService } from '../../services/util/util.service';
import { UserModel } from 'src/app/models/user/user.service';

const ULOPEN = 'ul-open';
const ULCLOSE = 'ul-close';
const MENUOPEN = 'menu-open';
const MENUCLOSE = 'menu-close';
const CARETRIGHT = 'fa-caret-right';
const CARETDOWN = 'fa-caret-down';
const ISACTIVE = 'is-active';
const MINPCSIZE = 1024;

@Component({
	selector: 'footer-menu',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit {
	username = "";
	insertion = "";
	surname = "";
	public href: string;
	public showGrey: boolean;

	constructor(
        public loginService: LoginService,
        public userService: UserModel,
		private util: UtilService
	) {

	}

	ngOnInit() {
		this.checkRoute();
		// this.checkScrollbar();
	}

	/**
	  * checkRoute
	  *
	  * Check what our route is
	  *
	  * @author       Jurre Kon <jurre@safira.nl>
	  * @lastEdit     12-07-2018 12:10
	  * @lastEditBy   Jurre Kon <jurre@safira.nl>
	  */
	public checkRoute(): void {
		this.href = window.location.href.split('/')[3];
	}

	/**
      * checkRoute
      *
      * Check what our route is
      *
      * @author       Jurre Kon <jurre@safira.nl>
      * @lastEdit     12-07-2018 12:10
      * @lastEditBy   Jurre Kon <jurre@safira.nl>
      */
	 public checkScrollbar(): void {
		let _pageEnd = this.util.getElement('#page-end');

		// return;

        let interval = setInterval(() =>{
            if(_pageEnd.offsetHeight !== 0){
                clearInterval(interval);

                if(_pageEnd.offsetHeight + 176 > window.innerHeight){
                    // this.util.addClass(_pageEnd, 'none');
				} else {
					this.util.removeClass(_pageEnd.parent, 'none');
                }

            }
        }, 50);
    }

}
