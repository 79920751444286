import { Injectable } from '@angular/core';
import { UtilService } from '../util/util.service';
import { Router } from '@angular/router';
import { ConfigService } from '../config/config.service';

@Injectable({
	providedIn: 'root'
})
export class AuthorisationService {

	constructor(
		private util: UtilService,
		private router: Router,
		private config: ConfigService
	) { }


	/*
	* 	canAccess
	*
	*	Checks if an user is authorised to the given page
	*/
	canAccess(module, label) {
		if(this.config.rememberMe){
			if(this.util.getByLocalStorage('authToken') != ''){
				return true;
			} else {
				return false;
			}
		} else if(!this.config.rememberMe){
			if(this.util.getBySessionStorage('authToken') != ''){
				return true;
			} else {
				return false;
			}
		}
	}

	/*
	* 	goToLogin
	*
	*	Navigate user to the login page
	*/
	goToLogin(force?){
		var tmpThis = this;
		setTimeout(function(){
			if(force ||  tmpThis.router.url.indexOf('login') < 0 && tmpThis.router.url.indexOf('register') < 0 && tmpThis.router.url.indexOf('forgotPassword') < 0 ){
				tmpThis.router.navigateByUrl('/login');
			}
		}, 0);		
	}
}