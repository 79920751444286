import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormComponent } from './form.component';
import { InputComponent } from '../input/input.component';
import { SelectComponent } from '../select/select.component';
import { TextareaComponent } from '../textarea/textarea.component';
import { RadioComponent } from './../radio/radio.component';
import { ToggleComponent } from './../toggle/toggle.component';
import { CheckboxComponent } from './../checkbox/checkbox.component';

import { InputDirective } from '../input/input.directive';
import { SelectDirective } from './../select/select.directive';
import { ButtonComponent } from '../button/button.component';
import { SwitchToggleComponent } from '../switch-toggle/switch-toggle.component';
import { searchboxComponent } from '../searchbox/searchbox.component';
import { FormsModule } from '@angular/forms';


@NgModule({
	declarations: [
		FormComponent,
		InputComponent,
		InputDirective,
		SelectComponent,
		SelectDirective,
		TextareaComponent,
		RadioComponent,
		ToggleComponent,
		CheckboxComponent,
        ButtonComponent,
        SwitchToggleComponent,
        searchboxComponent,
	],
	imports: [
		CommonModule,
        RouterModule,
        FormsModule,
	],
	exports: [
		FormComponent,
		InputComponent,
		SelectComponent,
		TextareaComponent,
		RadioComponent,
		ToggleComponent,
		CheckboxComponent,
        ButtonComponent,
        SwitchToggleComponent,
        searchboxComponent,
	],
	providers: [
		FormComponent,
		InputComponent,
		SelectComponent,
		TextareaComponent,
		RadioComponent,
		ToggleComponent,
		CheckboxComponent,
        ButtonComponent,
        SwitchToggleComponent,
        searchboxComponent,
	]
})
export class FormModule { }
