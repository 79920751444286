import { Injectable } from '@angular/core';
import { ToasterService } from '../toaster/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class PrintModalService {
 
constructor(private toast: ToasterService) { }

  /**
  * modalContent  
  *
  * Content of the label. HTML can be applied.
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  modalContent: string;

  /**
  * active  
  *
  * wether the modal is visible or not
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  active: boolean = false;

  /**
  * modalWidth  
  *
  * width of the modal. Standard of 1 centimeter
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  modalWidth: number = (1 * 37.7952755906);

  /**
  * modalWidthInCm
  *
  * width of the modal, in CM
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
 modalWidthInCm: number = (1 * 37.7952755906);

  /**
  * modalHeight  
  *
  * height of the modal. Standard of 1 centimeter
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  modalHeight: number = (1 * 37.7952755906);

  /**
  * modalHeightInCm  
  *
  * height of the modal in CM
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
 modalHeightInCm: number = (1 * 37.7952755906);
  
  /**
  * actionButtonsWidth  
  *
  * Width of the action buttons div. When printing the
  * label we need to reduce the width because
  * the buttons aren't there
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  actionButtonsWidth: number = 225;

  /**
  * content  
  *
  * Sets the content for the label
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  content(content: string) {
    this.modalContent = content;
  }

  /**
  * open  
  *
  * opens the popup
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  open() {
    this.active = true;
  }

  /**
  * close  
  *
  * Closes the popup
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  close() {
    this.active = false;
  }

 /**
  * width  
  *
  * Sets the width for the label. Calculates the width in pixels
  * based of centimeters.
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  width(width) {
    var widthInPixels = (width * 37.7952755906);
    this.modalWidthInCm = width;
    this.modalWidth = (Math.ceil(widthInPixels) + this.actionButtonsWidth); //add 225 for the actions width
  }

 /**
  * height  
  *
  * Sets the height for the label. Calculates the height in pixels
  * based of centimeters.
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  height(height) {
    var heightInPixels = (height * 37.7952755906);
    this.modalHeightInCm = height;
    this.modalHeight = heightInPixels;
  }

  /**
  * printLabel  
  *
  * Prints the label. Used when first showing the modal
  *
  * @author 		Mark de Mol <mark@safira.nl>
  * @lastEdit		30-05-2018 13:00
  * @lastEditBy	Mark de Mol <mark@safira.nl>
  * @returnType	void
  */
  printLabel( html? ) {
	let printContents, popupWin;
	
	//if no HTML passed we may assume
	//we're using the modal to print
	if(!html) {
		printContents = document.getElementById('modal-content').innerHTML;
		html = printContents;
	}

    popupWin = window.open('', '_blank', 'scrollbars=0,resizable=0,top=0,left=0,height='+this.modalHeight+',width='+(this.modalWidth - this.actionButtonsWidth));
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Printen</title>
          <style>
          body { padding:10px; font-family: Arial; background: #fff; font-size: 22px; margin: 0; line-height: 31px; color: #333333; }
          p { margin: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
          .before, .after { display:none; }
          </style>
        </head>
      <body onload="window.print();window.close()">${html}</body>
      </html>`
    );
	popupWin.document.close();
	
	this.toast.success('Printen gestart');
  }



}
