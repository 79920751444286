import { Directive } from '@angular/core';

@Directive({
	selector: 'sTextarea'
})
export class textareaDirective {

	constructor() { }

}
