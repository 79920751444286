import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sButton',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less']
})
export class ButtonComponent implements OnInit {

  @Input('color') color = false;

  @Input('right') right = false;

  @Input('round') round = false;

  @Input('outline') outline = false;

  @Input('fullWidth') fullWidth = false;

  @Input('disabled') disabled = false;

  constructor() { }

  ngOnInit() {

  }

}
