import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { officeRouting } from './office.routes';
import { ManageComponent } from './view/manage/manage.component';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { MenuModule } from 'src/app/core/components/menu/menu.module';
import { FooterModule } from 'src/app/core/components/footer/footer.module';
import { EditComponent } from './view/edit/edit.component';
import { WorkflowModulesModule } from '../workflow-modules/workflow-modules.module';
import { PopupModule } from 'src/app/core/components/popup/popup.module';
import { OfficeCreateComponent } from './view/create/create.component';


@NgModule({
  imports: [
    BrowserModule,
	officeRouting,
	FormsModule,
    FormModule,
    MenuModule,
    FooterModule,
    WorkflowModulesModule,
    PopupModule,
  ],
  declarations: [
    ManageComponent,
    EditComponent,
    OfficeCreateComponent
  ],
  providers: [

  ]
})
export class OfficeModule { }
