import { Routes, RouterModule } 	from '@angular/router';
import { SearchComponent } from './view/search/search.component';
import { DetailsComponent } from './view/details/details.component';
import { AllCustomersComponent } from './view/all-customers/all-customers.component';
import { EditComponent } from './view/edit/edit.component';

export const customerRoutes: Routes = [
	{
		path: 			'search',  
		component: 		SearchComponent
	},
	{
		path: 			'allCustomers',  
		component: 		AllCustomersComponent
	},
	{
		path: 			'details/:workflowId',  
		component: 		DetailsComponent
	},
	{
		path: 			'edit/:workflowId',  
		component: 		EditComponent
	},
];

export const customerRouting = RouterModule.forChild(customerRoutes);