import { OnInit, Component, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { ToasterService } from "src/app/core/services/toaster/toaster.service";
import { UserModel } from "src/app/models/user/user.service";

@Component({
    selector: 'officeCreate',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.less']
  })

export class OfficeCreateComponent implements OnInit {
    @ViewChild('emailfield') emailField: ElementRef;

    constructor(public router: Router, public toaster: ToasterService, public userModel: UserModel) {}

    public office = {'name': '', 'manager': '', 'email': '', 'password': ''};

    ngOnInit(): void {
    }

    /**
     * cancelCreate
     *
     * function to go back to manage component
     *
     * @author  Eric van Doorn <eric@safira.nl>
     */
    public cancelCreate() {
        this.router.navigate(['/office/manage/']);
    }

    /**
     * createOffice
     *
     * function to create a new office
     *
     * @author  Eric van Doorn <eric@safira.nl>
     */
    public createOffice() {
        // Validate form data before creating
        if(this.validateForm()) {
            let data = {'name': this.office['name'], 'manager': this.office['manager'], 'email':  this.office['email'], 'password':  this.office['password']};
            this.userModel.createOffice(data).then(result => {
                if(result == 'UsernameError') {
                    this.toaster.error('Het ingevoerde emailadres bestaat al.');
                    this.emailField.nativeElement.focus();
                }
                else if(result['error']) {
                    this.toaster.error('Er is iets mis gegaan bij het aanmaken van het nieuwe kantoor.');
                } else {
                    this.router.navigate(['/office/manage']);
                    setTimeout(() => {
                        this.toaster.success('Nieuw kantoor succesvol aangemaakt.');
                    }, 500);
                }
            })
        }
    }

    /**
     * validateForm
     *
     * validates the input of the create office form
     *
     * @author  Eric van Doorn <eric@safira.nl>
     */
    private validateForm() {
        if(this.office['name'] == '' || this.office['manager'] == '' || this.office['email'] == '' || this.office['password'] == '') {
            this.toaster.error('Een van de velden is niet gevuld');
            return false;
        } else if (!this.office['email'].includes('@') || !this.office['email'].includes('.')) {
            this.toaster.error('Incorrect email adres.');
            return false;
        }

        return true;
    }

}
