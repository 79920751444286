import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/models/user/user.service';
import { LoginService } from 'src/app/core/services/login/login.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/models/customer/customer.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';
import { Router } from '@angular/router';

@Component({
  selector: 'edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class EditComponent implements OnInit {
    private userId: number;
    public email: string;
    public first_name: string;
    public insertion: string;
    public surname: string;
    public password: string;
    public newPassword: string = '';
    public deadline: string;
    public deadlineBelasting: string;
    public error = 0;
    public message: string;
    public result = 0;

  constructor(
    public UserModel: UserModel,
    public LoginService: LoginService,
    public ToasterService: ToasterService,
    public CustomerService: CustomerService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
			this.userId = params['userId'];
        });
        //extra check to see if user is allowed
        this.LoginService.checkManager().then(data => {
            if(data){
                this.UserModel.getUserById(this.userId).then(data => {
                    this.email = data['email'];
                    this.first_name = data['first_name'];
                    this.insertion = data['insertion'];
                    this.surname = data['surname'];
                    if(data['two_factor_authentication'] === "1"){
                        document.getElementById('myonoffswitch').setAttribute('checked','checked');
                    }
                    this.UserModel.checkUserGroups(this.userId).then(data => {
                        for (let group of <any>data) {
                            if(group['name'] === "Management"){
                                document.getElementById('myonoffswitch-manager').setAttribute('checked','checked');
                            }else if(group['name'] === "Invoice"){
                                document.getElementById('myonoffswitch-invoice').setAttribute('checked','checked');
                            }
                        }
                    });
                    if(this.userId === this.LoginService.userId){
                        // this.router.navigate(['myTasks']);
                    }
                });
            }else{
                this.router.navigate(['myTasks']);
            }
        });
    }

    userNameFormat(firstname, insertion, surname){
        var firstNameCheck = (firstname !== null) ? firstname : '';
        var insertionCheck = (insertion !== null) ? insertion : '';
        var surnameCheck = (surname !== null) ? surname : '';

        var name = firstNameCheck + " " +  insertionCheck + " " + surnameCheck;

        return name;
    }

    updateEmail(event){
        this.UserModel.updateUserEmail(this.userId,event.target.value).then(data => {
            this.newPassword = <any>data['password'];
            this.error = <any>data['error'];
            if(data['error'] === 1){
                this.message = "Deze email adress is al in gebruik";
                this.ToasterService.error('Fout tijdens opslaan');
            }else{
                this.message = "Het nieuw wachtwoord is: " + data['password'];
                this.ToasterService.success('Succesvol opgeslagen');
            }
            this.result = 1;
        });
    }

    updatePasswordOfUser(event){
        this.UserModel.updateUserPassword(this.userId,event.target.value).then(data =>{
            if(data === null){
                this.ToasterService.success('Succesvol opgeslagen');
                this.message = "Het wachtwoord is aangepast";
                this.result = 1;
            }else{
                this.ToasterService.error('Fout tijdens opslaan');
            }
        });
    }

    updateField(event){
        this.UserModel.updateUserField(this.userId, event.target.name, event.target.value).then(data =>{
            if(data === ''){
                this.ToasterService.success('Succesvol opgeslagen');
            }else{
                this.ToasterService.error('Fout tijdens opslaan');
            }
        });
    }

    update2fa(event){
        var checkValue = (event.target.checked == true) ? "1" : "0";
        this.UserModel.updateTwoFactorAuthentication(this.userId, checkValue).then(data => {
        });
    }

    reset2fa(){
        this.UserModel.resetTwoFactorAuthentication(this.userId)
    }

    updateManager(event){
        var checkValue = (event.target.checked == true) ? 'add' : 'remove';
        this.UserModel.updateManagerGroup(this.userId, checkValue);
    }

    updateInvoice(event){
        var checkValue = (event.target.checked == true) ? 'add' : 'remove';
        this.UserModel.updateInvoiceGroup(this.userId, checkValue);
    }
}
