import { OnInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { ToasterService } from "src/app/core/services/toaster/toaster.service";
import { UserModel } from "src/app/models/user/user.service";
import { Location } from '@angular/common';
import { CustomerService } from "src/app/models/customer/customer.service";
import { ControlValueAccessor } from "@angular/forms";

@Component({
    selector: 'singleTaskCreate',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.less']
})

export class SingleTaskCreateComponent implements OnInit {


    constructor(public router: Router,
        public toaster: ToasterService,
        public userModel: UserModel,
        private _location: Location,
        public customerService: CustomerService
    ) { }

    public task: Task;
    public invoiceSwitch: any;
    public invSwitch: boolean = false;
    public customer: any = null;

    ngOnInit(): void {
        this.task = { name: '', description: '', deadline: '', frequency: 'NONE' };

        if (!this.customerService.customerList) {
            let set = [];
            this.customerService.getCustomerList().then(result => {
                for (const line in result as any) {
                    let t = [];
                    t['id'] = result[line]['user_id'];
                    t['value'] = result[line]['first_name'];
                    set.push(t);
                }
                this.customerService.customerList = set;
            })
        }
    }

    /**
     * cancelCreate
     *
     * function to go back to manage component
     *
     * @author  Eric van Doorn <eric@safira.nl>
     */
    public cancelCreate() {
        this._location.back();
    }

    /**
     * createOffice
     *
     * function to create a new office
     *
     * @author  Eric van Doorn <eric@safira.nl>
     */
    public async createSingleTask() {
        // Validate form data before creating
        if (this.validateForm()) {

            let data = {
                'name': this.task.name,
                'description': this.task.description,
                'deadline': this.task.deadline,
                'frequency': this.task.frequency,
                'invoice': this.invSwitch,
                'customerId': this.customer
            };

            // Promise hell bad >:( defensive programming + async await good :)
            try {
                const result = await this.userModel.createSingleTask(data);

                this.router.navigate(['/dashboard']);
                setTimeout(() => {
                    return this.toaster.success('Nieuw kantoor succesvol aangemaakt.');
                }, (500));

            } catch (e) {
                this.toaster.error('Er is iets mis gegaan bij het aanmaken van het nieuwe kantoor.');
            }
        }
    }

    /**
     * setInvSwitch
     *
     * sets the change of the invoice switch to the variable
     *
     *
     * @param checked
     */
    public setInvSwitch(checked) {
        this.invSwitch = checked;
    }

    /**
     * validateForm
     *
     * validates the input of the create single task form
     *
     * @author  Eric van Doorn <eric@safira.nl>
     */
    private validateForm() {
        if (this.task['name'] == '' || this.task['description'] == '' || this.task['deadline'] == '') {
            this.toaster.error('Een van de velden is niet gevuld');
            return false;
        }

        // if customer is set, check if the input is inside the list, else it is a non existing customer
        if (this.customer) {
            let found = this.customerService.customerList.some(el => el.id === this.customer);
            if (!found) {
                this.toaster.error('Opgegeven klant komt niet voor in de klanten lijst.');
                return false;
            }
        }

        return true;
    }
}

interface Task {
    name: string;
    description: string;
    deadline: string
    frequency: "NONE" | "WEEKLY" | "MONTHlY" | "QUARTERLY" | "ANNUALLY";
}