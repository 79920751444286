import { Injectable } from '@angular/core';
import { ToasterService } from '../toaster/toaster.service';
@Injectable({
  providedIn: 'root'
})
export class ConfirmboxService {


	/**
	 * content
	 *
	 * Content of the label. HTML can be applied.
	 *
	 * @author 		Mark de Mol <mark@safira.nl>
	 * @lastEdit	30-05-2018 13:00
	 * @lastEditBy	Mark de Mol <mark@safira.nl>
	 * @returnType	void
	 */
	content: string;

	/**
	 * title
	 *
	 * title of the label. HTML can be applied.
	 *
	 * @author 		Mark de Mol <mark@safira.nl>
	 * @lastEdit	30-05-2018 13:00
	 * @lastEditBy	Mark de Mol <mark@safira.nl>
	 * @returnType	void
	 */
	title: string;

	/**
	 * active
	 *
	 * wether the modal is visible or not
	 *
	 * @author 		Mark de Mol <mark@safira.nl>
	 * @lastEdit	30-05-2018 13:00
	 * @lastEditBy	Mark de Mol <mark@safira.nl>
	 * @returnType	void
	 */
	active: boolean = false;

	/**
	 * confirmAction
	 *
	 * Holds the callback for the confirm button
	 *
	 * @author 		Mark de Mol <mark@safira.nl>
	 * @lastEdit	30-05-2018 13:00
	 * @lastEditBy	Mark de Mol <mark@safira.nl>
	 * @returnType	void
	 */
	confirmAction : any;

	/**
	 * cancelAction
	 *
	 * Holds the callback for the cancel button
	 *
	 * @author 		Mark de Mol <mark@safira.nl>
	 * @lastEdit	30-05-2018 13:00
	 * @lastEditBy	Mark de Mol <mark@safira.nl>
	 * @returnType	void
	 */
	cancelAction : any;

	/**
	 * confirm
	 *
	 * Function to call when you want to use the confirmbox component.
	 *
	 * @author 		Mark de Mol <mark@safira.nl>
	 * @lastEdit	30-05-2018 13:00
	 * @lastEditBy	Mark de Mol <mark@safira.nl>
	 * @returnType	void
	 */
	confirm(title: string, message: string, confirm:()=>void, cancel:()=>void){
		this.active = true;
		this.content = message;
		this.title = title;
		this.confirmAction = confirm;
		this.cancelAction = cancel;
	}

	/**
	 * onConfirm
	 *
	 * Default actions for confirm
	 *
	 * @author 		Mark de Mol <mark@safira.nl>
	 * @lastEdit	30-05-2018 13:00
	 * @lastEditBy	Mark de Mol <mark@safira.nl>
	 * @returnType	void
	 */
	onConfirm() {
		this.confirmAction();
		this.active = false;
	}

	/**
	 * onCancel
	 *
	 * Default actions for cancel
	 *
	 * @author 		Mark de Mol <mark@safira.nl>
	 * @lastEdit	30-05-2018 13:00
	 * @lastEditBy	Mark de Mol <mark@safira.nl>
	 * @returnType	void
	 */
	onCancel() {
		this.cancelAction();
		this.active = false;
	}

 }


