import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/models/customer/customer.service';
import { Router } from '@angular/router';
import { officeService } from 'src/app/models/office/office.service';
import { TasksModel } from 'src/app/models/tasks/tasks.service';
import { UserModel } from 'src/app/models/user/user.service';

@Component({
    selector: 'all-customers',
    templateUrl: './all-customers.component.html',
    styleUrls: ['./all-customers.component.less']
})

export class AllCustomersComponent implements OnInit {

    public financialYears: any = new Array();
    public moduleName: any;
    public companees: any = new Array();
    public result: any = new Array();
    public deadlineStatus = "Deadline verstreken";
    public finished: any;
    public todo: number;
    public total: number;
    public modules: any = new Array();
    public moduleId: number = 1;
    public loading: boolean = true;

    constructor(public CustomerService: CustomerService, private router: Router, public officeService: officeService, public TasksModel: TasksModel, private userService: UserModel) { }

    ngOnInit() {
        if (this.officeService.modules.length == 0) {
            this.officeService.getModulesForOffice(this.userService.userId, true).then(data => {
                this.officeService.modules = data[0];
                this.modules = data[0];
                this.getAllCustomers(this.moduleId);
            })
        } else {
            this.modules = this.officeService.modules;
            this.getAllCustomers(this.moduleId);
        }

        
    }

    /**
     * companyReady
     *
     * checks if the company has finished all tasks
     *
     * @author      	Arjan Koemans <arjan@safira.nl>
     * @lastEdit     13-01-2020 16:28
     * @lastEditBy   Arjan Koemans <arjan@safira.nl>
     */
    public companyReady(company) {
        return (company['task_order'] == company['task_total'] ? 'green' : 'red');
    }

    /**
     * checkDeadline
     *
     * Check witch color soud be donse
     *
     * @author      	Romain van Maanen <romain@safira.nl>
     * @lastEdit     21-11-2018 10:33
     * @lastEditBy   Romain van Maanen <romain@safira.nl>
     */
    public checkDeadline(stringDate) {
        const currentDate = new Date().toJSON().slice(0, 10);
        if (currentDate == stringDate || currentDate < stringDate) {
            this.deadlineStatus = "Deze week";
            return 'green';
        } else {
            this.deadlineStatus = "Deadline verstreken";
            return 'red';
        }
    }

    /**
     * convertDate
     *
     * convert date to wanted format
     *
     * @author      	Romain van Maanen <romain@safira.nl>
     * @lastEdit     21-11-2018 10:33
     * @lastEditBy   Romain van Maanen <romain@safira.nl>
     */
    public convertDate(stringDate) {
        const date = new Date(stringDate);
        const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];

        var day = date.getDate();
        var month = monthNames[date.getMonth()];
        var year = date.getFullYear();

        var yeardegits = year.toString().slice(-2);

        var format = day + " " + month + " '" + yeardegits;
        return format;
    }

    routeClick(id) {
        this.router.navigateByUrl('/details/' + id);
    }

    /**
     * setCompanyResults
     *
     * set the correct results for the given year and sorts them by task order, then calls setCountInfo
     * @param year
     * @author Eric van Doorn <eric@safira.nl>
     */
    public setCompanyResults(year) {
        this.companees = [];
        this.CustomerService.customersYear = year;
        this.companees = this.result[year]['companees'];

        this.sortCompanyResults();
    }

    /**
     * sortCompanyResults
     *
     * Sorts the company results to task order then company name
     *
     * @author Eric van Doorn <eric@safira.nl>
     */
    private sortCompanyResults() {
        this.companees.sort(function (a, b) {
            let to1 = a['task_order'];
            let to2 = b['task_order'];

            let cn1 = a['company_name'].toLowerCase();
            let cn2 = b['company_name'].toLowerCase();

            if (to1 < to2) return -1;
            if (to1 > to2) return 1;
            if (cn1 < cn2) return -1;
            if (cn1 > cn2) return 1;
            return 0;
        })

        this.setCountInfo();
    }


    /**
     * getModules
     *
     * returns the modules that should be showed on this page in the dropdown box. filters out master only modules
     *
     * @author Eric van Doorn <eric@safira.nl>
     */
    public getModules() {
        let mods = [];
        this.modules.forEach(module => {
            if (module['master_only'] == '0') mods.push(module);
        });
        return mods;
    }

    /**
     * setCountInfo
     *
     * set the correct info for the total numbers
     *
     * @author Eric van Doorn <eric@safira.nl>
     */
    private setCountInfo() {
        this.total = 0;
        this.finished = 0;
        this.companees.forEach(company => {
            this.total ++;
            if(company['task_order'] == company['task_total'] && company['completed'] == '1') this.finished ++;
        });
        this.todo = this.total - this.finished;
        this.loading = false;
    }

    /**
     * setModuleResults
     *
     * sets the selected module as active and makes sure the data is refreshed
     * @param module
     * @author Eric van Doorn <eric@safira.nl>
     */
    public setModuleResults(module) {
        this.moduleId = +module;
        this.CustomerService.customersYear = null;
        this.getAllCustomers(this.moduleId);
    }

    /**
     * getAllCustomers
     *
     * returns all customers for a module id
     * @param moduleId
     * @author Arjan Koemans <arjan@safira.nl>
     */
    private getAllCustomers(moduleId = 1) {
        this.loading = true;
        this.financialYears = [];
        this.CustomerService.getAllCustomers(moduleId).then(data => {
            var i = 0;
            var year = typeof (this.CustomerService.customersYear) !== 'undefined' ? this.CustomerService.customersYear : data.key;

            for (let key in data) {
                this.financialYears.push(key);
                this.moduleName = data[key]['module_name'];
                this.result[key] = data[key];
                i++;

                if (i == 1 && year == null) {
                    year = key;
                }

                if (year == key) {
                    this.setCompanyResults(year);
                }
            }
        });
    }

  }
