import { Injectable } from '@angular/core';
import { AjaxService } from '../ajax/ajax.service';
import { Router } from '@angular/router'
import { ConfigService } from '../config/config.service';
import { UtilService } from '../util/util.service';
import { CustomerService } from 'src/app/models/customer/customer.service';
import { UserModel } from 'src/app/models/user/user.service';
import { officeService } from 'src/app/models/office/office.service';


@Injectable({
	providedIn: 'root'
})
export class LoginService {
	public username: any[];
	public password: any[];
	public error: boolean = false;
	public sessionToken: any[];
    // public array: any[];
    public qrcode: any;
    public googleCode: any;
    public errorCode: boolean = false;
    public skipBarcode: any;
    public passwordReset: boolean = false;
    public manager = false;
    public invoice = false;
    public userId: any;
    public errorType: any;
    public userFirstname: string;


	constructor(
        public customerService: CustomerService,
		private ajax: AjaxService,
		private router: Router,
		private config: ConfigService,
        private util: UtilService,
        private user: UserModel,
        private officeService: officeService
	) { }

	/*
	  *
	  * logout
	  *
	  */
	logout() {
		let promise = new Promise((resolve, reject) => {
			this.ajax.get('user/logout', '.content').then(
				res => { // Success
					localStorage.removeItem('authToken');
					sessionStorage.removeItem('authToken');
                    this.config.rememberMe = false;
                    this.customerService.customers = [];

					localStorage.removeItem('apiKey');
					this.router.navigate(['login']);
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});
	}

	userLogin() {
		let promise = new Promise((resolve, reject) => {
			let data = { username: this.username, password: this.password };
			this.ajax.post('user/login', data, '.content').then(
                res => { // Success
					if ((<any>res).error == 0) {
                        localStorage.setItem('apiKey', 'aTr5hGxktNVTacQGL6zCuHJB');
                        this.error = false;
                        if((<any>res).use_two_factor_authentication === 0){
                            if ((<any>res).token != '') {
                                if (this.config.rememberMe) {
                                    localStorage.setItem('authToken', (<any>res).token);
                                } else if (!this.config.rememberMe) {
                                    sessionStorage.setItem('authToken', (<any>res).token);
                                }
                                this.getUserData().then(result => {
                                    if(result) {
                                        if(this.user.isMaster) {
                                            this.router.navigate(['office/manage'])
                                        } else {
                                            this.router.navigate(['dashboard']);
                                        }
                                    }
                                })
                            }
                        }else{
                            this.sessionToken = (<any>res).token;
                            if ((<any>res).mobile_authentication === '0') {
                                this.qrcode = res['qrcode'];
                                this.skipBarcode = res['skipBarcode'];
                            }else{
                                this.skipBarcode = res['skipBarcode'];
                                if (this.config.rememberMe) {
                                    localStorage.setItem('authToken', (<any>res).token);
                                } else if (!this.config.rememberMe) {
                                    sessionStorage.setItem('authToken', (<any>res).token);
                                }
                                this.getUserData().then(result => {
                                    if(result) {
                                        if(this.user.isMaster) {
                                            this.router.navigate(['office/manage'])
                                        } else {
                                            this.router.navigate(['dashboard']);
                                        }
                                    }
                                })
                            }
                        }
                        this.username = [];
                        this.password = [];
					} else {
                        this.error = true;
                        this.errorType = res['error'];
                        localStorage.setItem('authToken', '');
                        sessionStorage.setItem('authToken', '');
                    }
					resolve(res);
				},
                msg => { // Error
					reject(msg);
				}
			);
        });

        return promise;
    }

    /**
    *
    * update Qrcode
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			04-01-2019 10:53
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateQrCode($sessionToken){
        let promise = new Promise((resolve, reject) => {
            let data = {'sessionToken':$sessionToken};
            this.ajax.post('user/updateQrCode', data, '.content').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * checkGoogleCode
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			04-01-2019 12:27
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public checkGoogleCode(){
        let promise = new Promise((resolve, reject) => {
            let data = { sessionToken: this.sessionToken, googleCode: this.googleCode };
            this.ajax.post('user/checkGoogleCode', data, '.content').then(
                res => { // Success
                    if ((<any>res) === true) {
                        this.errorCode = false;
                        if (this.config.rememberMe) {
                            localStorage.setItem('authToken', (<any>this.sessionToken));
                        } else if (!this.config.rememberMe) {
                            sessionStorage.setItem('authToken', (<any>this.sessionToken));
                        }
                        this.googleCode = [];
                        this.router.navigate(['myTasks']);
                    }else{
                        this.errorCode = true;
                    }
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

	getUserInfoByToken() {
		let promise = new Promise((resolve, reject) => {
			if (this.config.rememberMe) {
				var data = { sessionToken: localStorage.getItem('authToken') };
			} else if (localStorage.getItem('authToken') != '') {
                if(sessionStorage.getItem('authToken') != '' && sessionStorage.getItem('authToken') != null){
                    var data = { sessionToken: sessionStorage.getItem('authToken') };;
                }else{
                    var data = { sessionToken: localStorage.getItem('authToken') };;
                }
            }else if (!this.config.rememberMe) {
				var data = { sessionToken: sessionStorage.getItem('authToken') };
            }else{
                var data = { sessionToken: sessionStorage.getItem('authToken') };
            }
			this.ajax.post('user/getUserDataByToken', data).then(
                res => { // Success
                    this.userId = res['user_id'];
                    this.manager = res['manager'];
                    this.invoice = res['invoice'];
                    this.userFirstname = res['first_name'];
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});
		return promise;
	}

	/*
	*	forgotPassword
	*
	*	Send forgot password mail
	*/
	forgotPassword(username, targetDiv?) {
		let promise = new Promise((resolve, reject) => {
			this.ajax.post(
				'/user/passwordForgot/send',
				{ 'username': username, 'domain': window.location.protocol + '//' + window.location.host },
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
    }

    /**
    *
    * Get All Users
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			10-01-2019 13:13
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public generateRestPasswordPage(){
        let promise = new Promise((resolve, reject) => {
            let data = {'username':this.username};
            this.ajax.post('user/generateRestPasswordPage', data,'.content').then(
                res => { // Success
                    this.passwordReset = true;
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * Get All Users
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			10-01-2019 16:13
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public resetPasswordFromEmail(hash){
        let promise = new Promise((resolve, reject) => {
            let data = {'password':this.password, 'hash':hash};
            this.ajax.post('user/resetPasswordFromEmail', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
    return promise;
    }

    /**
    *
    * check if user is manager
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			16-01-2019 11:09
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public checkManager(){
        let promise = new Promise((resolve, reject) => {
            this.ajax.get('user/checkManager', '.main-container').then(
                res => { // Success
                    this.manager = <any>res;
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    public getUserData() {
        let promise = new Promise((resolve, reject) => {
            this.getUserInfoByToken().then(data => {
                this.user.isMaster = data['master'];
                this.user.isManager = data['manager'];
                this.user.userId = data['user_id'];
                this.user.userData = data;
                let filterEnabled = true;

                if(this.user.isMaster) filterEnabled = false;
                this.officeService.getModulesForOffice(this.user.userId, filterEnabled).then(data => {
                    this.officeService.modules = data[0];
                })
                resolve(true);
            });
        })
        return promise;

    }
}
