import { Component, OnInit } from '@angular/core';
import { MyTasksService } from 'src/app/models/myTasks/my-tasks.service';
import { LoginService } from 'src/app/core/services/login/login.service';
import { TasksModel } from "../../../../models/tasks/tasks.service";
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user/user.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';
import { CustomerService } from 'src/app/models/customer/customer.service';
import { officeService } from 'src/app/models/office/office.service';

@Component({
	selector: 'my-tasks',
	templateUrl: './my-tasks.component.html',
	styleUrls: ['./my-tasks.component.less']
})
export class MyTasksComponent implements OnInit {
	public activeTask: any;
	deadlineStatus = "Deadline verstreken";
    public taskOrder: any;
    public financialYears: any = new Array();
    public modules: any = new Array();
    public dashboardFilters

	constructor(
		private myTasksService: MyTasksService,
		public loginService: LoginService,
        public TasksModel: TasksModel,
        private router: Router,
        public userService: UserModel,
        public toaster: ToasterService,
        public customersService: CustomerService,
        private officeService: officeService
	) { }

	ngOnInit() {
        if(this.userService.isMaster) this.router.navigate(['/office/manage']);
		this.TasksModel.getUserTasks().then(data => {
            var taksToDisplayInFilter = [];
            for (let key in data) {
                let value = data[key];
                let tmp;

                tmp = {
                    'order': data[key]['task_order'],
                    'name': data[key]['task_name']
                }

                taksToDisplayInFilter.push(tmp);

                if(this.financialYears.indexOf( value['financial_year'] ) == -1){
                    this.financialYears.push( value['financial_year'] );
                }

                this.officeService.getModulesForOffice(this.userService.userId, true).then(result => {
                    result[0].forEach(module => {
                       if(this.modules.indexOf(module['name']) == -1) {
                           this.modules.push(module['name']);
                       }
                    });
                })

            }

            let uniqueTaskDiplayFilter = [];
            for (const key in taksToDisplayInFilter) {
                const element = taksToDisplayInFilter[key];
                let found = false;

                for (const key in uniqueTaskDiplayFilter) {
                    const uniqueElement = uniqueTaskDiplayFilter[key];

                    if (uniqueElement['name'] == element['name']) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    uniqueTaskDiplayFilter.push(element);
                }
            }

            uniqueTaskDiplayFilter.sort(function(a, b) {
                return a['order'] - b['order'];
            });

            this.taskOrder = uniqueTaskDiplayFilter;
        });
	}

	  /**
	* checkDeadline
	*
	* Check color
	*
	* @author      	Romain van Maanen <romain@safira.nl>
	* @lastEdit     21-11-2018 10:33
	* @lastEditBy   Romain van Maanen <romain@safira.nl>
	*/
	checkDeadline(stringDate){
		const currentDate = new Date().toJSON().slice(0, 10);
		if(currentDate == stringDate){
		  this.deadlineStatus = "Deze week";
		  return 'green';
		} else if(currentDate < stringDate){
		  this.deadlineStatus = "Deze week";
		  return 'green';
		}else{
		  this.deadlineStatus = "Deadline verstreken";
		  return 'red';
		}
	}

	  /**
		* convertDate
		*
		* convert date to wanted format
		*
		* @author      	Romain van Maanen <romain@safira.nl>
		* @lastEdit     21-11-2018 10:33
		* @lastEditBy   Romain van Maanen <romain@safira.nl>
		*/
	convertDate(stringDate){
		const date = new Date(stringDate);
		const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni","Juli", "Augustus", "September", "Oktober", "November", "December"];

		var day = date.getDate();
		var month = monthNames[date.getMonth()];
		var year = date.getFullYear();

		var yeardegits = year.toString().slice(-2);

		var format = day + " " + month + " '" + yeardegits;
		return format;
	}

	routeClick(id){
		this.router.navigateByUrl('/details/' + id);
    }

    userName(firstName, insertion, lastName){
        var firstName = ( firstName != null) ? firstName : '';
        var insertion = ( insertion != null) ? insertion : '';
        var lastName = ( lastName != null) ? lastName : '';
        return firstName + " " + insertion + " " + lastName;
    }

    /**
     * generateFinancialYears
     *
     * Calls the endpoint to generate financial years
     *
     * @author  Eric van Doorn <eric@safira.nl>
     */
    public generateFinancialYears() {
        this.TasksModel.generateFinancialYears().then(result => {
            if(result == true) {
                this.toaster.success('Boekjaren gegenereerd');
            } else {
                this.toaster.error('fout bij generen boekjaren')
            }
        })
    }

    /**
     * createSingleTask
     *
     * navigates to the page to create a single task
     */
    public createSingleTask() {
        this.router.navigate(['/myTasks/create'])
    }
}
