import { Component, Input } from '@angular/core';
import { PrintModalService } from '../../services/printmodal/printmodal.service';

@Component({
  selector: 'modal',
  templateUrl: './printmodal.component.html',
  styleUrls: ['./printmodal.component.less']
})
export class PrintModalComponent {

  constructor(public modalService: PrintModalService) { }

}
