import { Component, OnInit } from '@angular/core';
import { TasksModel } from "../../../../models/tasks/tasks.service";
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { LoginService } from 'src/app/core/services/login/login.service';
import { UserModel } from 'src/app/models/user/user.service';
import { Router } from '@angular/router';
import { officeService } from 'src/app/models/office/office.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';

@Component({
  selector: 'manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.less']
})
export class ManageComponent implements OnInit {
    public activeTask: any;
    public typelist = 0;
    deadlineStatus = "Deadline verstreken";
    taskArray: any;
    public current: Object = {id: null, name: '', modules: null};
    public createNewActive: boolean = false;

    constructor(
        public TasksModel: TasksModel,
        public UserModel: UserModel,
        public loginService: LoginService,
        private router: Router,
        public officeService: officeService,
        private ToasterService: ToasterService,
    ) {

    }

    ngOnInit() {
        this.loginService.checkManager().then(data => {
            if(this.loginService.manager){
                this.UserModel.getOfficeList();
            }else{
                this.router.navigate(['myTasks']);
            }
        });
    }

  /**
	* checkDeadline
	*
	* Check witch color soud be donse
	*
	* @author      	Romain van Maanen <romain@safira.nl>
	* @lastEdit     21-11-2018 10:33
	* @lastEditBy   Romain van Maanen <romain@safira.nl>
	*/
    checkDeadline(stringDate){
        const currentDate = new Date().toJSON().slice(0, 10);
        if(currentDate == stringDate){
            this.deadlineStatus = "Deze week";
            return 'green';
        } else if(currentDate < stringDate){
            this.deadlineStatus = "Deze week";
            return 'green';
        }else{
            this.deadlineStatus = "Deadline verstreken";
            return 'red';
        }
    }

  /**
	* convertDate
	*
	* convert date to wanted format
	*
	* @author      	Romain van Maanen <romain@safira.nl>
	* @lastEdit     21-11-2018 10:33
	* @lastEditBy   Romain van Maanen <romain@safira.nl>
	*/
    convertDate(stringDate){
        const date = new Date(stringDate);
        const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni","Juli", "Augustus", "September", "Oktober", "November", "December"];

        var day = date.getDate();
        var month = monthNames[date.getMonth()];
        var year = date.getFullYear();

        var yeardegits = year.toString().slice(-2);

        var format = day + " " + month + " '" + yeardegits;
        return format;
    }

    routeClick(id){
		this.router.navigateByUrl('/user/edit/' + id);
    }

    userName(firstName, insertion, lastName){
        var firstName = ( firstName != null) ? firstName : '';
        var insertion = ( insertion != null) ? insertion : '';
        var lastName = ( lastName != null) ? lastName : '';
        return firstName + " " + insertion + " " + lastName;
    }

    deactivateUser(userId){
        this.UserModel.deactivateUser(userId).then(data => {
            this.UserModel.getOfficeList();
        });
    }

    selectList(event){
        if(event.target['1'].selected){
            this.UserModel.getInactifeOfficeList();
            this.typelist = 1;
        }else{
            this.UserModel.getOfficeList();
            this.typelist = 0;
        }
    }

    activationUser(userId){
        this.UserModel.activationUser(userId).then(data => {
            this.UserModel.getInactifeOfficeList();
        });
    }

    public isDisabled(typelist) {
        if(typelist == 1) return true;
        if(typelist == 0) return false;
        return false;
    }



    /**
     * setModules
     *
     * gets and sets the modules of the userId provided, this opens the popup of the module settings
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param userId number required
     */
    public setModules(userId, username) {
        this.officeService.getModulesForMaster(userId).then(data => {
            this.officeService.modules = data;
            this.current['id'] = userId;
            this.current['name'] = username;
            this.current['modules'] = data;
        })
    }

    /**
     * turnOnModule
     *
     * calls the same named function in the services to turn on the module
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param userId number required
     * @param moduleId number required
     */
    public turnOnModule(userId, moduleId) {
        this.officeService.turnOnModule(userId, moduleId).then(result => {
            this.ToasterService.success('Module aangezet');
        })
    }

    /**
     * turnOffModule
     *
     * calls the same named function in the services to turn off the module
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param userId number required
     * @param moduleId number required
     */
    public turnOffModule(userId, moduleId) {
        this.officeService.turnOffModule(userId, moduleId).then(result => {
            this.ToasterService.success('Module uitgezet');
        })
    }

    public openCreateOffice() {
        this.router.navigateByUrl('/office/create');
    }
}
