import { Injectable } from '@angular/core';


const REQUIRED = ': is required!';
const MAXLENGTH = ': Cant be longer then ';
const EMAIL = ': is not a valid email!';
const DATE = ': Date not valid!';
const PASSWORDMINLENGTH = ': Password too short!';
const PASSWORDSTRENGTH = ': Password not strong enough!';
const TIME = ': Time not valid!';

const ERRORTEXT = 'error-text';
const VALIDTEXT = 'valid-text';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  private elementForValidation = [
		'sinput',
		'sselect'
  ]

  private passwordRanks = [
    'Too Short',
    'Weak',
    'Medium',
    'Strong',
    'Very Strong'
  ]

  private passwordColors = {
    'Too Short': '',
    'Weak': '#D50000',
    'Medium': '#FFA000',
    'Strong': '#1B5E20',
    'Very Strong': '#76FF03'
  }
  
  constructor() { }

  /**
    * validationPossible
    *
    * check if theres an element which needs to be validated
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 10:40
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  validationPossible(nativeElement: any): boolean {
    let form = nativeElement.childNodes[0];
    let elements = form.childNodes;

		let possible = false;

		Array.prototype.forEach.call(elements, (element) => {
			if (this.elementValidationPossible(element.localName)) {
				possible = true;
			}		
		});

		return possible;
	}

  /**
    * validationPossible
    *
    * check if element.localName is in the elementForValidation array
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 10:40
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
	elementValidationPossible(localName: string): boolean {
		return this.elementForValidation.indexOf(localName) > -1;
	}

  /**
    * sinputValidate
    *
    * validate the input of sInput
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 10:40
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  sinputValidate(element: any): any {
		let input = element.children[0].children[1];
		let label = element.children[0].children[0];
    
		// requiredValidation
		if (element.innerHTML.includes('required=')) {
       if (!this.requiredValidation(input)) { return [false, label.textContent + REQUIRED]; }
		}
		
		// maxLengthValidation
		if (element.attributes['maxLength']) {
				if (!this.maxLengthValidation(element, input)) { return [false, label.textContent + MAXLENGTH + element.attributes['maxLength'].nodeValue]; }
		}

		// emailValidation
		if (input.type === 'email') {
			if (!this.emailValidation(input)) { return [false, label.textContent + EMAIL];	}
		}

    // dateValidation
		if (input.type === 'date') {
			if (!this.dateValidation(input)) {  return [false, label.textContent + DATE];	}
    }

    // passwordValidation
		if (input.type === 'password') {
      let passwordValidationReturnType = this.passwordValidation(input, label, 'Strong');
      if (!passwordValidationReturnType[0]) { return passwordValidationReturnType; }
		}

    // timeValidation
		if (input.type === 'time') {
			if (!this.timeValidation(input)) {	return [false, label.textContent + TIME]; }	
    }

    // for IE
    if (input.attributes['data-fieldtype'].nodeValue === 'time') {
			if (!this.timeValidation(input)) {	return [false, label.textContent + TIME]; }	
    } 
    
    if (input.attributes['data-fieldtype'].nodeValue === 'date') {
      if (!this.dateValidation(input)) {  return [false, label.textContent + DATE];	}
    }
    
		return [true, ''];
  }
  
  /**
    * sselectValidate
    *
    * validate the input of sSelect
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 10:40
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
	sselectValidate(element: any): any {
		let select = element.children[0].children[1].children[0];
		let label = element.children[0].children[0];

    if (select.value === '') {

      return [false, label.textContent +': Select an option!'];
    }

		return [true, ''];
  }

  /**
    * checkPasswordStrength
    *
    * checks the strength of the password
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  checkPasswordStrength(input: any): string {
    let upper = /[A-Z]/,
    lower = /[a-z]/,
    number = /[0-9]/,
    special = /[^A-Za-z0-9]/,
    minLength = 8,
    score = 0;

    if (input.value.length < minLength) {
      return 'Too Short';
    }

    // Increment the score for each of these conditions
    if (input.value.match(upper)) score++;
    if (input.value.match(lower)) score++;
    if (input.value.match(number)) score++;
    if (input.value.match(special)) score++;

    // Penalize if there aren't at least three char types
    if (score < 3) score--;

    if (input.value.length > minLength) {
      // Increment the score for every 2 chars longer than the minimum
      score += Math.floor((input.value.length - minLength) / 2);
    }

    if (score < 3) { return 'Weak'; }
    if (score < 4) { return 'Medium' };
    if (score < 6) { return 'Strong' };
    
    return 'Very Strong'
  }

  /**
    * passwordValidation
    *
    * checks if the password is valid
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  passwordValidation(input: any, label: any, wantedStrength: string): any {
    if (input.value === '') { return [true, '']; }

    let passwordStrength = this.checkPasswordStrength(input);

    if (passwordStrength === 'Too Short') { 
      return [false, label.textContent + PASSWORDMINLENGTH] 
    }

    if (this.passwordRanks.findIndex(rank => rank === passwordStrength) < this.passwordRanks.findIndex(rank => rank === wantedStrength)) {
      return  [false, label.textContent + PASSWORDSTRENGTH];
    }

    return  [true, ''];
  }

  /**
    * requiredValidation
    *
    * checks if the required input is not empty
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  requiredValidation(input: any): boolean {
    if (input.value === '') {
      return false;
    }
    return true;
  }

  /**
    * maxLengthValidation
    *
    * checks input is not greater then the maxLength
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  maxLengthValidation(element: any, input: any): boolean {
    if (input.value.length > element.attributes['maxLength'].nodeValue){
      return false;
    }
    return true;
  }

  /**
    * emailValidation
    *
    * checks if the input is an email
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  emailValidation(input: any): boolean {
    if (input.value === '') { return true; }
    if (!input.value.match(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i)) {
      return false;
    }
    return true;
  }

  /**
    * dateValidation
    *
    * checks if the input is a date
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  dateValidation(input: any): boolean {
    if (input.value === '') { return true; }
    if (!input.value.match(/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/i)) {
      return false;
    }
    return true;
  }

  /**
    * timeValidation
    *
    * checks if the input is a time
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  timeValidation(input: any): boolean {
    if (input.value === '') { return true; }
    if (!input.value.match(/^([01]?\d|2[0-3]):([0-5]\d)$/i)) {
      return false;
    }
    return true;
  }

  
  /**
    * getRankIndexByStringName
    *
    * get the index of the strenght rank
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  getRankIndexByStringName(strengthRank: string) {
    return this.passwordRanks.findIndex(rank => rank === strengthRank);
  }

  /**
    * getColorByRank
    *
    * get the color of the password strength rank
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
  getColorByRank(strengthRank: string){
    return this.passwordColors[strengthRank];
  }
}
