import { Directive } from '@angular/core';

@Directive({
	selector: 'sSelect'
})
export class SelectDirective {

	constructor() { }

}
