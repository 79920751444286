import { Component, OnInit } from '@angular/core';
import { AjaxService } from '../../../../core/services/ajax/ajax.service';
import { UtilService } from '../../../../core/services/util/util.service';
import { UserModel } from '../../../../models/user/user.service';
import { ToasterService } from '../../../../core/services/toaster/toaster.service';
import { LoginService } from 'src/app/core/services/login/login.service';
import { ConfigService } from 'src/app/core/services/config/config.service';
import { Router } from '@angular/router'
import { AuthorisationService } from 'src/app/core/services/authorisation/authorisation.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
    // formFields: any[];


	/*
	*	constructor
	*
	* 	Default constructor
	*/
    constructor(
        private http: AjaxService,
        private util: UtilService,
        private user: UserModel,
        private toast: ToasterService,
        private ajax: AjaxService,
        public loginService: LoginService,
        private router: Router,
        public configService: ConfigService,
        public auth: AuthorisationService,
    ) {
        // this.formFields = [];
        // this.setFormFields();
    }

	/*
	* ngOnInit
	*
	* Checks if an user is alrady logged in
	*/
    ngOnInit() {
        if (this.auth.canAccess('','')) {
            if(this.user.isMaster) {
                this.router.navigate(['office/manage'])
            } else {
                this.router.navigate(['dashboard']);
            }
        }

        this.changeBackgroundImage();
    }

	/**
	  * changeBackgroundImage
	  *
	  * Set timer to change background image
	  *
	  * @author       Jurre Kon <jurre@safira.nl>
	  * @lastEdit     09-11-2018 14:15
	  * @lastEditBy   Jurre Kon <jurre@safira.nl>
	  */
    public changeBackgroundImage() {
        let i = 1;
        let interval = setInterval(() => {
            let _background = this.util.getElement('.background-' + i);
            if (window.location.pathname === '/login') {
                _background.style.opacity = 0;
            }
            if (i === 3) {
                i = 1
            } else {
                i++;
            }

            _background = this.util.getElement('.background-' + i);
            if (window.location.pathname === '/login') {
                _background.style.opacity = 1;
            }

        }, 10000);
    }

	/**
	  * togglePassword
	  *
	  * Toggle the type of the password field so the user can see their input.
	  *
	  * @author       Jurre Kon <jurre@safira.nl>
	  * @lastEdit     19-11-2018 08:45
	  * @lastEditBy   Jurre Kon <jurre@safira.nl>
	  */
    public togglePassword($test) {
        let _passwordField = this.util.getElement('#password');

        if (_passwordField.type == 'password') {
            _passwordField.type = 'text';
        } else {
            _passwordField.type = 'password';
        }
    }

    /**
	  * login
	  *
	  * Login function that supports two factor athentication
	  *
	  * @author       Romain <romain@safira.nl>
	  * @lastEdit     4-1-2019 08:45
	  * @lastEditBy   Romain van Maanen <romain@safira.nl>
	  */
    Login() {
        this.loginService.userLogin().then(data => {
            if (this.loginService.error === false) {
                if (this.loginService.skipBarcode === false) {
                    document.getElementById("modal-background").style.display = 'unset';
                    document.getElementById("modal-2fa").style.display = 'block';
                    document.getElementById("modal-2fa-code").style.display = 'none';
                }

            }
        });
    }

    /**
     * close modal
     *
     * closes barcode modal
     *
     * @author       Romain <romain@safira.nl>
     * @lastEdit     4-1-2019 08:45
     * @lastEditBy   Romain van Maanen <romain@safira.nl>
     */
    closeModal() {
        document.getElementById("modal-background").style.display = 'none';
        this.loginService.googleCode = [];
    }

    confirmMobile() {
        this.loginService.updateQrCode(this.loginService.sessionToken).then(data => {
            document.getElementById("modal-2fa").style.display = 'none';
            document.getElementById("modal-2fa-code").style.display = 'block';
            this.loginService.qrcode = '';
        });
    }

    checkCode() {
        this.loginService.checkGoogleCode();
    }


    /**
     * Start the reset password protocool
     *
     * @author       Romain <romain@safira.nl>
     * @lastEdit     10-1-2019 08:45
     * @lastEditBy   Romain van Maanen <romain@safira.nl>
     */
    forgotPassword() {
        this.router.navigate(['forgotPassword']);
    }
}
