import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/models/customer/customer.service';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/core/services/util/util.service';
import { UserModel } from 'src/app/models/user/user.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';
import { LoginService } from 'src/app/core/services/login/login.service';

@Component({
	selector: 'search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {
    public current: Object = {id: null, name: '', modules: null};

	constructor(
        public customerService: CustomerService,
        public UserModel: UserModel,
        private router: Router,
        private util: UtilService,
        private ToasterService: ToasterService,
        public loginService: LoginService
	) { }

	ngOnInit() {
        if(this.customerService.searchString && this.customerService.searchString != '') this.customerService.searchCustomers();
    }

    routeDetailsClick(id){
        let parrent = this.util.findParentWithClass(event.target,'content-box');
        let selected = this.util.findChildrenWithClass(parrent, 'options');
        let result = 0;
        for (let row of selected){
            if(row['selected'] === true){
                result = row['value']
            }
        }
		this.router.navigateByUrl('/details/' + result);
    }

    routeEditClick(event){
        let parrent = this.util.findParentWithClass(event.target,'content-box');
        let selected = this.util.findChildrenWithClass(parrent, 'options');
        let result = 0;
        for (let row of selected){
            if(row['selected'] === true){
                result = row['value']
            }
        }
		this.router.navigateByUrl('/edit/' + result);
    }

    createCompanee(){
        this.UserModel.createCompanee().then(data => {
            this.router.navigateByUrl('/edit/' + data);
        });

    }

    createBoekyear(){
        this.UserModel.createNewBookYears().then(data => {
            this.ToasterService.success("Boekjaren aangemaakt");
        });
    }

    /**
     * setModules
     *
     * gets and sets the modules of the userId provided, this opens the popup of the module settings
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param userId number required
     */
    public setModules(userId, username) {
        this.current['id'] = userId;
        this.current['name'] = username;
        this.customerService.getModulesForCustomer(this.current['id']).then( result => {
            this.current['modules'] = result;
        });
    }

    /**
     * turnOnModule
     *
     * calls the same named function in the services to turn on the module
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param userId number required
     * @param moduleId number required
     */
    public turnOnModule(userId, moduleId) {
        this.customerService.turnOnModule(userId, moduleId).then(result => {
            this.customerService.searchCustomers();
            this.ToasterService.success('Module aangezet');
        })
    }

    /**
     * turnOffModule
     *
     * calls the same named function in the services to turn off the module
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param userId number required
     * @param moduleId number required
     */
    public turnOffModule(userId, moduleId) {
        this.customerService.turnOffModule(userId, moduleId).then(result => {
            this.customerService.searchCustomers();
            this.ToasterService.success('Module uitgezet');
        })
    }


    /**
     * getWorkflowsForCustomer
     *
     * returns the workflows that are not from single tasks module, used to show in dropdown for customer search
     *
     * @param customer required
     */
    public getWorkflowsForCustomer(customer) {
        let set = [];
        customer['financial_year'].forEach(workflow => {
            if(workflow['system_module_name'] != 'single_tasks_module') set.push(workflow);
        });

        // sort by year descending
        set.sort((a, b) => {
            if(a.year > b.year) return -1;
            if(a.year < b.year) return 1;
            if(a.year = b.year) return 0;
        })
        return set;
    }

}
