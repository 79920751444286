import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/models/customer/customer.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user/user.service';
import { LoginService } from 'src/app/core/services/login/login.service';
import { IfStmt } from '@angular/compiler';
import { MyTasksService } from 'src/app/models/myTasks/my-tasks.service';
import { TasksModel } from 'src/app/models/tasks/tasks.service';

@Component({
	selector: 'customerDetails',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.less']
})
export class DetailsComponent implements OnInit {
    private deadlineStatus = "";
    private completedBye = "";
    private completedByeBool = false;
	private modalState = "close";
	private changeChecker = 0;
	private result: any;
    private result2: any;
	public taskOrder = "";
	public workflowId: number;
	public taskName = "";
	public taskId = "";
    public errorText: any;

	constructor(
        public CustomerService: CustomerService,
        public userModel: UserModel,
        public loginService: LoginService,
        public TasksModel: TasksModel,
		private route: ActivatedRoute,
        private router: Router,
        private myTasksService: MyTasksService,

	) {
		this.route.params.subscribe(params => {
			this.workflowId = params['workflowId'];
        });
	}

	ngOnInit() {
		this.getCustomerDetails();

        if(!this.userModel.userList){
            this.userModel.getUserListEmployee().then(data => {
            });
        }
	}


    public getCustomerDetails() {
        this.CustomerService.getCustomersDetails(this.workflowId).then(result => {
        });
    }
	/**
	  * changeStatus
	  *
	  * convert date to wanted format
	  *
	  * @author       	Romain van Maanen <romain@safira.nl>
	  * @lastEdit     	21-11-2018 10:33
	  * @lastEditBy   	Romain van Maanen <romain@safira.nl>
	  */
	changeStatus(taskStatus) {
		//send the value to the database
		this.CustomerService.SetTaksStatus(taskStatus, this.taskId).then(data => {
			this.result = data;
			if (this.result === 'true') {
				this.CustomerService.getCustomersDetails(this.workflowId);
				document.getElementById("modal").style.visibility = 'hidden';
			}
		});

		//set the moddle visability to invis

	}

	/**
	  * checkModalState
	  *
	  * convert date to wanted format
	  *
	  * @author       	Romain van Maanen <romain@safira.nl>
	  * @lastEdit     	21-11-2018 10:33
	  * @lastEditBy   	Romain van Maanen <romain@safira.nl>
	  */
	checkModalState($value?) {
		switch ($value) {
			case "close":
				this.modalState = "close";
				document.getElementById("modal").style.visibility = 'hidden';
				document.getElementById("modal-error").style.visibility = 'hidden';
				return "close";
			case "open":
				this.modalState = "open";
				return "open";
			default:
				return "close";
		}
	}

	/**
	  * closeModal
	  *
	  * @author       	Romain van Maanen <romain@safira.nl>
	  * @lastEdit     	21-11-2018 10:33
	  * @lastEditBy   	Romain van Maanen <romain@safira.nl>
	  */
	closeModal() {
		document.getElementById("modal").style.visibility = 'hidden';
		document.getElementById("modal-error").style.visibility = 'hidden';
	}

	moveDivs(event, taskOrder, taskName, workflowTaskId, moduleName = ''){
        document.getElementById("delayed_task_order").style.opacity='1';
        var x = event.clientX;
		//controle maken of je hem wel mag aanpassen.
		this.CustomerService.checkTaskFlow(workflowTaskId, taskOrder).then(data => {
            if(data === "false user"){
                this.errorText = "U bent niet de correcte gebruiker om dit uit te voeren";
                    document.getElementById("modal").style.visibility='hidden';
                    var location = this.calculateLocation(taskOrder);

                    document.getElementById("modal-error").style.left=x - 250 + "px";
                    document.getElementById("modal-error").style.top=location+"px";
                    document.getElementById("modal-error").style.visibility='visible';
            }else{
                this.result2 = data;
                if(this.result2 === "true"){
                    document.getElementById("modal-error").style.visibility='hidden';
                    var location = this.calculateLocation(taskOrder);
                    this.taskOrder = taskOrder;
                    this.taskName = taskName;
                    this.taskId = workflowTaskId;
                    var tasks = this.CustomerService.customersDetail;

                    for (let row of tasks){
                        if(row['workflow_task_id'] === workflowTaskId){
                            var selectedStatus = row['completed'];
                        }
                    }
                    // var attr = document.getElementById("not-completed").getAttribute("checked");
                    if(selectedStatus === "1"){
                        if (document.getElementById("not-completed").getAttribute("checked") !== null) {
                                document.getElementById("not-completed").removeAttribute("checked");
                        }
                        document.getElementById("completed").setAttribute("checked", "checked");
                    }else{
                        if (document.getElementById("completed").getAttribute("checked") !== null) {
                            document.getElementById("completed").removeAttribute("checked");
                        }
                            document.getElementById("not-completed").setAttribute("checked", "checked");
                    }

                    document.getElementById("modal").style.left=x - 250 + "px";
                    document.getElementById("modal").style.top=location+"px";
                    document.getElementById("modal").style.visibility='visible';
                }else{
                    this.errorText = "Je kunt de status niet wijzigen omdat andere taken nog niet voltooid zijn of taken hierna zijn al uitgevoerd.";
                    document.getElementById("modal").style.visibility='hidden';
                    var location = this.calculateLocation(taskOrder);

                    document.getElementById("modal-error").style.left=x - 250 + "px";
                    document.getElementById("modal-error").style.top=location+"px";
                    document.getElementById("modal-error").style.visibility='visible';
                }
            }
		});
	}

	/**
	 * checkDeadline
	 *
	 * convert date to wanted format
	 *
	 * @author       	Romain van Maanen <romain@safira.nl>
	 * @lastEdit     	21-11-2018 10:33
	 * @lastEditBy   	Romain van Maanen <romain@safira.nl>
	 */
	checkDeadline(task) {
		const currentDate = new Date().toJSON().slice(0, 10);
		if (task.completed === "1") {
            this.deadlineStatus = "Afgerond op " + this.convertDate(task['completed_date']);
            if(task['user_completed'] != null){
                this.completedBye = "door " + task['user_completed'];
                this.completedByeBool = true;
            }
			return ['green', 'check-circle'];
		} else {
            this.completedByeBool = false;
			if (currentDate > task.deadline) {
				this.deadlineStatus = "Deadline verstreken";
				return ['red', 'exclamation-circle'];
			} else if (task.transparent === undefined) {
				this.deadlineStatus = "Deze week";
				return ['green', 'arrow-circle'];
			} else {
				this.deadlineStatus = "";
				return ['green', ''];
			}
		}
	}

	/**
	  * checkDeadlineWeeks
	  *
	  * convert date to wanted format
	  *
	  * @author       	Romain van Maanen <romain@safira.nl>
	  * @lastEdit     	21-11-2018 10:33
	  * @lastEditBy   	Romain van Maanen <romain@safira.nl>
	  */
	checkDeadlineWeeks(stringDate) {
		const date = new Date();
		const monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

		var day = date.getDate() + 7;
		var month = monthNames[date.getMonth()];
		var year = date.getFullYear();

		var format = year + "-" + month + "-" + day;

		const currentDate = new Date().toJSON().slice(0, 10);

		if (stringDate < currentDate)
			return "false";
		else
			return "true";
	}

	/**
	  * convertDate
	  *
	  * convert date to wanted format
	  *
	  * @author       	Romain van Maanen <romain@safira.nl>
	  * @lastEdit     	21-11-2018 10:33
	  * @lastEditBy   	Romain van Maanen <romain@safira.nl>
	  */
	convertDate(stringDate) {
		const date = new Date(stringDate);
		const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];

		var day = date.getDate();
		var month = monthNames[date.getMonth()];
		var year = date.getFullYear();

		var yeardegits = year.toString().slice(-2);

		var format = day + " " + month + " '" + yeardegits;
		return format;
	}

	/**
	  * calculateLocation
	  *
	  * convert date to wanted format
	  *
	  * @author       	Romain van Maanen <romain@safira.nl>
	  * @lastEdit     	21-11-2018 10:33
	  * @lastEditBy   	Romain van Maanen <romain@safira.nl>
	  */
	calculateLocation(taskorder) {
		var defaultLocation = 250;
		var calculatedLocation = 110 * (taskorder - 1);
		var newLocation = defaultLocation + calculatedLocation;
		return newLocation;
	}

	/**
	  * userName
	  *
	  * convert date to wanted format
	  *
	  * @author       	Romain van Maanen <romain@safira.nl>
	  * @lastEdit     	21-11-2018 10:33
	  * @lastEditBy   	Romain van Maanen <romain@safira.nl>
	  */
	userName(firstName, insertion, lastName) {
		var firstName = (firstName != null) ? firstName : '';
		var insertion = (insertion != null) ? insertion : '';
		var lastName = (lastName != null) ? lastName : '';
		return firstName + " " + insertion + " " + lastName;
	}

	/**
	  * routeClick
	  *
	  * @author       	Arjan Koemans <arjan@safira.nl>
	  */
	routeClick(id){
		this.workflowId = id;
		this.router.navigateByUrl('/details/' + this.workflowId);
		this.CustomerService.getCustomersDetails(this.workflowId);
    }


    /**
     * showSelect
     *
     * sets edit = true for the clicked index
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param index
     * @param id
     */
    public showSelect(index, id) {
        this.CustomerService.customersDetail[index]['edit'] = true;
    }


    /**
     * updateSingleTaskToEmployee
     *
     * calls the promise to update a single task to a new employee
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param $taskId
     * @param $employeeId
     * @param index
     */
    public updateSingleTaskToEmployee($taskId, $employeeId, index) {
        this.myTasksService.updateSingleTaskToEmployee($taskId, $employeeId).then(result => {
            this.CustomerService.customersDetail[index]['edit'] = false;
            this.getCustomerDetails();
        })
    }
}
