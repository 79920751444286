import { Routes, RouterModule } 	from '@angular/router';
import { AllTasksComponent } from '../tasks/view/all-tasks/all-tasks.component';
import { MyTasksComponent } from '../tasks/view/my-tasks/my-tasks.component';
import { SingleTaskCreateComponent } from './view/my-tasks/create/create.component';
import { taskDashboardComponent } from './view/task-dashboard/task-dashboard.component';

export const tasksRoutes: Routes = [
    {
        path:           '',
        redirectTo:     'login',
        pathMatch:      'full'
    },
	{
		path: 			'allTasks',
		component: 		AllTasksComponent
	},
	{
		path: 			'myTasks',
		redirectTo:     'dashboard',
        pathMatch:      'full'
	},
	{
		path: 			'myTasks/create',
		component: 		SingleTaskCreateComponent
    },
    {
        path:           'dashboard',
        component:      taskDashboardComponent
    }
];

export const takenRouting = RouterModule.forChild(tasksRoutes);
