import { Routes, RouterModule } 	from '@angular/router';
import { LoginComponent } from './view/login/login.component';
import { RegisterComponent } from './view/register/register.component';
import { ManageComponent } from './view/manage/manage.component';
import { ForgotPasswordComponent } from './view/forgot-password/forgot-password.component';
import { EditComponent } from './view/edit/edit.component';

export const userRoutes: Routes = [
	{
		path: 			'login',  
		component: 		LoginComponent
	},
	{
		path: 			'forgotPassword',  
		component: 		ForgotPasswordComponent
	},
	{
		path: 			'forgotPassword/:hash',  
		component: 		ForgotPasswordComponent
	},
	{
		path: 			'register',  
		component: 		RegisterComponent
	},
	{
		path: 			'user/activate/:hash',  
		component: 		RegisterComponent
    },
    {
		path: 			'user/manage',  
		component: 		ManageComponent
    },
    {
		path: 			'user/edit/:userId',  
		component: 		EditComponent
	}
];

export const userRouting = RouterModule.forChild(userRoutes);