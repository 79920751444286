import { Injectable } from '@angular/core';
import { RequestOptions, Response, Headers } from '@angular/http';
import { Http } from '@angular/http';
import { UtilService } from '../util/util.service';
import { ConfigService } from '../config/config.service';
import { Router } from '@angular/router'
@Injectable({
	providedIn: 'root'
})

export class AjaxService {
	constructor(
		public http: Http,
		private util: UtilService,
		private config: ConfigService,
		private router: Router
	) {
	}

	/*
	* get
	* 
	* Will perform a http get to the given target
	*/
	get(url, loadingTarget?) {
		this.showLoading(loadingTarget);
		let promise = new Promise((resolve, reject) => {
			this.http.get(this.config.apiUrl + url, this.getHeaders()).toPromise().then(
				res => { // Success
					this.removeLoading(loadingTarget);
					resolve(this.handleResponse(res));
				},
				msg => { // Error
					this.removeLoading(loadingTarget);
					if (msg.status === 403) {
                        this.router.navigate(['login'])
					}
					reject(msg);
				}
			);
		});
		return promise;
	}

	/*
	* post
	* 
	* Will perform a http post to the given target
	*/
	post(url, data, loadingTarget?) {
		this.showLoading(loadingTarget);

		let promise = new Promise((resolve, reject) => {
			this.http.post(this.config.apiUrl + url, data, this.getHeaders()).toPromise().then(
				res => { // Success
					this.removeLoading(loadingTarget);
					resolve(this.handleResponse(res));
				},
				msg => { // Error
					if (msg.status === 403) {
                        this.router.navigate(['login'])
					}
					this.removeLoading(loadingTarget);
					reject(msg);
				}
			);
		});
		return promise;
	}

	/*
	* delete
	* 
	* Will perform a http delete to the given target
	*/
	delete(url, loadingTarget?) {
		this.showLoading(loadingTarget);

		let promise = new Promise((resolve, reject) => {
			this.http.delete(this.config.apiUrl + url, this.getHeaders()).toPromise().then(
				res => { // Success
					this.removeLoading(loadingTarget);
					resolve(this.handleResponse(res));
				},
				msg => { // Error
					this.removeLoading(loadingTarget);
					reject(msg);
				}
			);
		});
		return promise;
	}

	/*
	* delete
	* 
	* Will perform a http delete to the given target
	*/
	put(url, data, loadingTarget?) {
		this.showLoading(loadingTarget);

		let promise = new Promise((resolve, reject) => {
			this.http.put(this.config.apiUrl + url, data, this.getHeaders()).toPromise().then(
				res => { // Success
					this.removeLoading(loadingTarget);
					resolve(this.handleResponse(res));
				},
				msg => { // Error
					this.removeLoading(loadingTarget);
					reject(msg);
				}
			);
		});
		return promise;
	}

	/*
	* upload
	* 
	* Will perform a http upload to the given target
	*/
	upload(url, file, loadingTarget?) {
		const formData: FormData = new FormData();
		if (file) {
			formData.append('files', file, file.name);
		}
		return this.post(url, formData, loadingTarget);
	}


	/*
	* showLoading
	* 
	* Will show a loading div inside the target
	*/
	showLoading(target) {
		if (typeof target !== 'undefined') {
			this.removeLoading(target);
			var element = this.util.getElement(target);
			var newElement = this.util.createElement('div', 'loader-wrapper', '');
			this.util.appendElement(element, newElement);

			var loader = this.util.getChild(element, '.loader-wrapper');
			var newElement = this.util.createElement('div', '', '');
			this.util.appendElement(loader, newElement);
		}
	}

	/*
	* removeLoading
	* 
	* Will remove the loading div inside the target
	*/
	removeLoading(target) {
		if (typeof target !== 'undefined') {
			target = this.util.getElement(target);
			target = this.util.getChild(target, '.loader-wrapper');
			this.util.removeElement(target);
		}
	}

	/*
	* handleResponse
	* 
	* Will convert the given response into the correct format
	*/
	handleResponse(res) {
		try {
			var result = JSON.parse(res._body);
			return result.data;
		} catch (e) {
			return res._body;
		}
	}

	/*
	* getHeaders
	* 
	* Will set the custom headers for a request
	*/
	getHeaders() {
        let headers = new Headers();
		if (this.config.rememberMe) {
			headers.append('AUTH-TOKEN', this.util.getByLocalStorage('authToken'));
		} else if(this.util.getByLocalStorage('authToken') != ''){
			headers.append('AUTH-TOKEN', this.util.getByLocalStorage('authToken'));
        }else if(!this.config.rememberMe){
			headers.append('AUTH-TOKEN', this.util.getBySessionStorage('authToken'));
		} 

		headers.append('API-KEY', this.util.getByLocalStorage('apiKey'));

		let opts = new RequestOptions();
		opts.headers = headers;

		return opts;
	}
}
