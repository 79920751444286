import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'sToggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.less'],
})
export class ToggleComponent implements OnInit {

	//holds the name of the input
  @Input('name') name: string;

	//holds the label
  @Input('label') label: string;

  //holds the value
  @Input('checked') checked = false;

  @Input('disabled') disabled = false;


  constructor(private elt: ElementRef) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.disabled !== false) {
      this.elt.nativeElement.childNodes[0].childNodes[1].childNodes[1].classList.add('disabled');
    }
  }

}
