import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './view/search/search.component';
import { DetailsComponent } from './view/details/details.component';
import { customerRouting } from './customer.routes';
import { MenuModule } from 'src/app/core/components/menu/menu.module';
import { EditComponent } from './view/edit/edit.component';
import { FooterModule } from 'src/app/core/components/footer/footer.module';
import { WorkflowModulesModule } from '../workflow-modules/workflow-modules.module';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { AllCustomersComponent } from './view/all-customers/all-customers.component';

@NgModule({
	imports: [
		CommonModule,
		customerRouting,
        MenuModule,
        FooterModule,
        WorkflowModulesModule,
        FormModule,
        FormsModule
	],
	declarations: [
		SearchComponent,
		DetailsComponent,
        EditComponent,
        AllCustomersComponent,
    ],
    exports: [

    ]
})
export class CustomerModule { }
