import { Routes, RouterModule } 	from '@angular/router';
import { OverviewComponent } from '../invoice/view/overview/overview.component';

export const invoiceRoutes: Routes = [
	{
		path: 			'overview',  
		component: 		OverviewComponent
	}
];

export const invoiceRouting = RouterModule.forChild(invoiceRoutes);