import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sCheckbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.less']
})
export class CheckboxComponent implements OnInit {

  @Input('fullColor') fullColor: boolean;

  @Input('label') label: string;

  @Input('checkBoxes') checkBoxes: any;

  constructor() { }

  ngOnInit() {
    
  }

}
