import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponsiveFramework } from '../../services/responsive-framework/responsive-framework.service';
import { AuthorisationService } from '../../services/authorisation/authorisation.service';


@Component({
	selector: 'header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.less']
})

export class HeaderComponent implements OnInit {
	/*
	*  constructor
	* 
	*  Start component
	*/
	constructor(
		public responsive: ResponsiveFramework,
		public auth: AuthorisationService
	) {
	}


	/*
	*  ngOnInit
	* 
	*  When component has started
	*/
	ngOnInit() {
		
	}
}
