import { Component, Input, forwardRef, HostListener, ViewEncapsulation, ViewContainerRef, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationService } from './../../services/validation/validation.service';

@Component({
  selector: 'sInput',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
	  multi: true
	}
  ],
  encapsulation: ViewEncapsulation.None
  
})
export class InputComponent implements ControlValueAccessor   {

	//holds the value
	@Input('value') _value = '';

	//holds the value
	valueLength:number = 0;

	//holds the label
	@Input('label') label: string;

	//holds the type
	@Input('type') type: string = 'text';

	//holds the name of the input
	@Input('name') name: string;

	//holds the placeholder
	@Input('placeholder') placeholder: string = '';

	//holds the disabled state
	@Input('disabled') disabled = false;

	//holds the readonly state
	@Input('readonly') readonly = false;

	//holds the readonly state
	@Input('required') required = false;
	
	//holds the maxlength
	@Input('maxLength') maxLength;

	//holds the name of the input
	@Input('change') _functionNameKeyUp: any;

	//holds the name of the input
	@Input('strength') strength: string;

	onChange: any = () => {};
	onTouched: any = () => {};

	/**
    * constructor
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	constructor(private viewContainerRef: ViewContainerRef, 
				private cdRef: ChangeDetectorRef, 
				private validationService: ValidationService,
				private elt: ElementRef
				) { }

	/**
    * update
    * 
    * Updates the value of the input
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	update(event) {
		this.value = event.target.value;
		this.valueLength = this.value.length;
	}

	/**
    * getter
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	get value() {
		return this._value;
	}
	
	/**
    * setter
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	set value(val) {
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}
	
	/**
    * registerOnChange
    * 
    * Registers the onChange function
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	registerOnChange(fn) {
		this.onChange = fn;
	}
	
	/**
    * registerOnTouched
    * 
    * Registers the onTouch function
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	registerOnTouched(fn) { 
		this.onTouched = fn;
	}
	
	/**
    * writeValue
    * 
    * Writes the value
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	writeValue(value) {
		this.value = value;
	}

	/**
    * HostListener keyup
    * 
    * update the input on keyup
	*
	* @author 		Mark de Mol <mark@safira.nl>
	* @lastEdit		15-06-2018 13:00
	* @lastEditBy	Mark de Mol <mark@safira.nl>
	*/
	@HostListener('keyup', ['$event']) keyup(event: KeyboardEvent) {
		this.update(event);

		if(typeof this._functionNameKeyUp !== "undefined") this.getParentComponent()[this._functionNameKeyUp](event);
	}

	getParentComponent(){
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
	}
	
  /**
    * checkStrength
    *
    * checks the strength of the password and updates the indicator
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     11-07-2018 12:10
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
	checkStrength() {
		let input = this.elt.nativeElement.childNodes[0].childNodes[1];
		let strength = this.validationService.checkPasswordStrength(input);
		let allPoints = this.elt.nativeElement.childNodes[0].childNodes[3].childNodes;

		Array.prototype.forEach.call(allPoints, (point) => {
			point.style.backgroundColor = '';
		});

		for (let i = 0; i < this.validationService.getRankIndexByStringName(strength); i++) {
			allPoints[i].style.backgroundColor = this.validationService.getColorByRank(strength);
		}		
	}

  /**
    * checkIfTypeIsPossibleForBrowser
    *
    * Checks if type is possible in the browser, returns type if possible otherwise the type is text
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     21-08-2018 11:32
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
	checkIfTypeIsPossibleForBrowser(type: string): string {
		let isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
		var is_safari = navigator.userAgent.indexOf("Safari") > -1;

		if (type === 'date' && isIE) {
			return 'text';
		} else if (type === 'time' && isIE) {
			return 'text';
		} else {
			return type;
		}	
	}

  /**
    * checkIfTypeIsPossibleForBrowser
    *
    * Returning the right regex for IE
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     21-08-2018 13:41
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
	checkForPattern(type: string): any {
		let isIE = /msie\s|trident\//i.test(window.navigator.userAgent);

		if (type === 'date' && isIE) {
			return /^(19|20)\d\d[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])$/i;
		} else if (type === 'time' && isIE) {
			return /^([01]?\d|2[0-3]):([0-5]\d)$/i;
		} else {
			return;
		}	
	}

  /**
    * checkIfTypeIsPossibleForBrowser
    *
    * Returning the right regex for IE
    *
    * @author       Wilfred van Eck <wilfred@safira.nl>
    * @lastEdit     21-08-2018 13:41
    * @lastEditBy   Wilfred van Eck <wilfred@safira.nl>
    */
    getPlaceholder(type: string): string {
		let isIE = /msie\s|trident\//i.test(window.navigator.userAgent);

		if (type === 'date' && isIE) {
			return 'yyyy-mm-dd';
		} else if (type === 'time' && isIE) {
			return 'hh:mm';
		} else {
			return '';
		}	
	}

}
