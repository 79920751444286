import { Injectable } from '@angular/core';
import { AjaxService } from '../../core/services/ajax/ajax.service';

@Injectable({
	providedIn: 'root'
})
export class TasksModel {
    public taskResults: any;
    public taskResultsFiltered: any;
    public filters: any = new Array();
    public noResultOnFilter: boolean = false;

    public invoiceResults: any;
	public date: any;

	constructor(private ajax: AjaxService) {

	}

	getUserTasks() {
        this.taskResults = [];
        this.taskResultsFiltered = [];
		let promise: any = new Promise((resolve, reject) => {
			this.ajax.get('myTasks/getUserTasks','.main-container').then(
                res => { // Success
                    this.taskResults = res;
                    this.taskResultsFiltered = res;
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
	}

	getAllTasks(){
		let promise = new Promise((resolve, reject) => {
			this.ajax.get('tasks/getAllTasks', '.main-container').then(
                res => { // Success
                    this.taskResults = res;
                    this.taskResultsFiltered = res;
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});
		return promise;
	}

    /**
    *
    * get all Factuur
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			20-12-2018 15:47
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public getAllInvoice(){
        let promise = new Promise((resolve, reject) => {
            this.ajax.get('tasks/getAllInvoice', '.main-container').then(
                res => { // Success
                    this.invoiceResults = res;
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * compleet Invoice
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			02-01-2019 09:47
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public compleetInvoice(worklfowTaskId){
        let promise = new Promise((resolve, reject) => {
            let data = {'worklfowTaskId':worklfowTaskId};
            this.ajax.post('tasks/compleetInvoice', data, '.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
     * generateFinanicalYears
     *
     * generates the finanical book years for the companees of this office
     *
     * @author  Eric van Doorn <eric@safira.nl>
     * @param   none
     */
    public generateFinancialYears() {
        let promise = new Promise((resolve, reject) => {
            this.ajax.post('user/office/createFinancialYear', {}).then(
                res => {
                    resolve(res);
                },
                msg => {
                    reject(msg);
                }
            )
        });
        return promise;
    }


    /**
     * setFilter
     *
     * sets change to the filter object and then applys the filter
     *
     * @author  Eric van Doorn <eric@safira.nl>
     * @param key string required
     * @param value string required
     */
    setFilter(key, value) {
        // if value 0, the filter has been reset, so remove the filter from the filters
        if(value == 'null') {
            delete this.filters[key];
        } else {
            this.filters[key] = value;
        }
        // apply the filters
        this.applyFilter();
    }

    /**
     * applyFilter
     *
     * applys the filters set in filters object
     *
     * @author  Eric van Doorn <eric@safira.nl>
     */
    applyFilter() {
        this.taskResultsFiltered = [];
        let firstFilter = true;
        // for each filter, filter the objects
        for (const filter in this.filters) {
            if (this.filters.hasOwnProperty(filter)) {

                // when its the first filter, take results from the full task results
                if(firstFilter) {
                    for (const index in this.taskResults) {
                        let task = this.taskResults[index];
                        if(task[filter] == this.filters[filter]) {
                            if(!this.taskResultsFiltered.some(e => e.workflow_task_id === task.workflow_task_id)) this.taskResultsFiltered.push(task);
                        }
                    }
                    firstFilter = false;
                // not first filter, then take the results of the filtered object to filter out the rest
                } else {
                    for (const index in this.taskResultsFiltered) {
                        // filter matches
                        this.taskResultsFiltered = this.taskResultsFiltered.filter(task => task[filter] == this.filters[filter]);
                    }
                }
            }
        }

        this.noResultOnFilter = false;
        // check if any tasks are in th efiltered object
        if(!this.taskResultsFiltered[0] || this.taskResultsFiltered.length === 0) {
            // filtered is empty, check if any filters where ste
            if(Object.keys(this.filters).length === 0 || this.filters.lenght === 0) {
                // no filters set, so set the full results
                this.taskResultsFiltered = this.taskResults;
            } else {
                // filters are set, but no tasks in the filtered object, that means there are no results on this filter setting
                this.taskResultsFiltered = [];
                this.noResultOnFilter = true;
            }
        }
    }

    /**
    * convertDate
    *
    * convert date to wanted format
    *
    * @author      	Romain van Maanen <romain@safira.nl>
    * @lastEdit     21-11-2018 10:33
    * @lastEditBy   Romain van Maanen <romain@safira.nl>
    */
	public convertDate(stringDate){
		const date = new Date(stringDate);
		const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni","Juli", "Augustus", "September", "Oktober", "November", "December"];

		var day = date.getDate();
		var month = monthNames[date.getMonth()];
		var year = date.getFullYear();

		var yeardegits = year.toString().slice(-2);

		var format = day + " " + month + " '" + yeardegits;
		return format;
    }

    /**
     * getRepeatingTaskInfo
     * Retrieves the information for a repeating task
     * 
     * @author Bas Hoppenbrouwers
     * @param task 
     */
    public getRepeatingTaskInfo(task) {
        return this.ajax.post('/myTasks/getRepeatingTaskInfo', {
            taskData: task
        });
    }

    public deleteRepeatingTask(repeatTaskId) {
        return this.ajax.post('/myTasks/deleteRepeatingTask', {
            taskRepeatId: repeatTaskId
        });
    }

    /**
     * periodFormatter
     *
     * sets the period based on start and end
     *
     * @author Eric van Doorn <eric@safira.nl>
     * @param start
     * @param end
     */
    public periodFormatter(start, end) {
        if(!start || !end) return '';

        if(start == end)   return "Maand: " + start;

        return "Maand: " + start + ' t/m ' + end;
    }
}
