import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { invoiceRouting } from './invoice.routes';
import { OverviewComponent } from './view/overview/overview.component';

import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { MenuComponent } from 'src/app/core/components/menu/menu.component';
import { MenuModule } from 'src/app/core/components/menu/menu.module';
import { FooterModule } from 'src/app/core/components/footer/footer.module';


@NgModule({
  imports: [
    BrowserModule,
    invoiceRouting,
    FormsModule,
    FormModule,
    MenuModule,
    FooterModule
  ],
  declarations: [
    OverviewComponent
  ],
  providers: [

  ]
})
export class InvoiceModule { }
