import { Component, OnInit } from '@angular/core';
import { TasksModel } from "../../../../models/tasks/tasks.service"
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { Router } from '@angular/router';
import { officeService } from 'src/app/models/office/office.service';
import { UserModel } from 'src/app/models/user/user.service';

@Component({
  selector: 'all-tasks',
  templateUrl: './all-tasks.component.html',
  styleUrls: ['./all-tasks.component.less']
})
export class AllTasksComponent implements OnInit {
  public activeTask: any;
  deadlineStatus = "Deadline verstreken";
  public taskOrder: any;
  public userNames: any;
  public financialYears: any = new Array();
  public checkFilterTask = false;
  public checkFilterUsername = false;
  public oldValueTask: any;
  public oldValueUsername: any;
  public modules: any = new Array();


constructor(
	public TasksModel: TasksModel,
    private router: Router,
    private officeService: officeService,
    private userService: UserModel
  ) {

  }

  ngOnInit() {
    this.TasksModel.getAllTasks().then(data => {
        var n = [];
		var taksToDisplayInFilter = [];

		for (let key in data) {
            let value = data[key];
            let tmp;

            let fullName = this.userName(value['first_name'], value['insertion'], value['surname'])
            let employeeId = value['user_id_employee'];
            let obj = {'fullName': fullName, 'id': employeeId};
            if(!n.some(e => e.id === obj.id)) n.push(obj);

            tmp = {
                'order': value['task_order'],
                'name': value['task_name']
            }

			taksToDisplayInFilter.push(tmp);

			if(this.financialYears.indexOf( value['financial_year'] ) == -1){
				this.financialYears.push( value['financial_year'] );
            }

            this.modules = this.officeService.modules.map(function(value,index) {return value['name']});
        }

        var unique2 = n.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })

        this.userNames = unique2.sort();


        let uniqueTaskDiplayFilter = [];
        for (const key in taksToDisplayInFilter) {
            const element = taksToDisplayInFilter[key];
            let found = false;

            for (const key in uniqueTaskDiplayFilter) {
                const uniqueElement = uniqueTaskDiplayFilter[key];

                if (uniqueElement['name'] == element['name']) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                if(element['order'] == '0') element['name'] = 'Losse taak';
                if(!uniqueTaskDiplayFilter.some(e => e.order === element.order)) uniqueTaskDiplayFilter.push(element);
            }
        }

        uniqueTaskDiplayFilter.sort(function(a, b) {
            return a['order'] - b['order'];
        });

        this.taskOrder = uniqueTaskDiplayFilter;
    });

  }

  /**
	* checkDeadline
	*
	* Check witch color soud be donse
	*
	* @author      	Romain van Maanen <romain@safira.nl>
	* @lastEdit     21-11-2018 10:33
	* @lastEditBy   Romain van Maanen <romain@safira.nl>
	*/
  checkDeadline(stringDate){
    const currentDate = new Date().toJSON().slice(0, 10);
    if(currentDate == stringDate){
      this.deadlineStatus = "Deze week";
      return 'green';
    } else if(currentDate < stringDate){
      this.deadlineStatus = "Deze week";
      return 'green';
    }else{
      this.deadlineStatus = "Deadline verstreken";
      return 'red';
    }
  }

  /**
	* convertDate
	*
	* convert date to wanted format
	*
	* @author      	Romain van Maanen <romain@safira.nl>
	* @lastEdit     21-11-2018 10:33
	* @lastEditBy   Romain van Maanen <romain@safira.nl>
	*/
  convertDate(stringDate){
    const date = new Date(stringDate);
    const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni","Juli", "Augustus", "September", "Oktober", "November", "December"];

    var day = date.getDate();
    var month = monthNames[date.getMonth()];
    var year = date.getFullYear();

    var yeardegits = year.toString().slice(-2);

    var format = day + " " + month + " '" + yeardegits;
    return format;
  }

    routeClick(id){
		this.router.navigateByUrl('/details/' + id);
    }

    userName(firstName, insertion, lastName){
        var firstName = ( firstName != null) ? firstName : '';
        var insertion = ( insertion != null) ? insertion : '';
        var lastName = ( lastName != null) ? lastName : '';
        return firstName + " " + insertion + (insertion != '' ? ' ' : '') + lastName;
    }

}
