import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UtilService {

	constructor() { }

	/*
	* appendElement
	*
	* Appends an element
	*/
	appendElement(parentElement, childElement) {
		parentElement.appendChild(childElement);
	}

	/*
	* removeElement
	*
	* Removes an element
	*/
	removeElement(element) {
		if (typeof element !== 'undefined') {
			this.remove(element);
		}
	}

	/*
	* createElement
	*
	* Creates an element
	*/
	createElement(type, className, content) {
		var element = document.createElement(type);
		element.innerHTML = content;
		element.className = className;

		return element;
	}

	/*
	* getElement
	*
	* Return an element by a selector
	*/
	getElement(selector) {
		if (selector.indexOf('.') >= 0) {
			selector = document.getElementsByClassName(selector.replace('.', ''))[0];
		} else {
			selector = document.getElementById(selector.replace('#', ''));
		}

		return selector;
	}

	/*
	* getElements
	*
	* Return elements by class name
	*/
	getElements(selector) {
		return document.getElementsByClassName(selector.replace('.', ''));
	}

	/*
	* getParent
	*
	* Return the parent of the given element
	*/
	getParent(child) {
		return child.parentNode;
	}

	/*
	* getParentByTagName
	*
	* Return the parent of element with tag name.
	*/
	getParentByTagName(element, tagname) {
		var parent;
		if (element === null || tagname === '') return;
		parent = element.parentNode;
		tagname = tagname.toUpperCase();

		while (parent.tagName !== "HTML") {
			if (parent.tagName === tagname) {
				return parent;
			}
			parent = parent.parentNode;
		}

		return parent;
	}

	/*
	* getElementsByTagName
	*
	* Return all elements with a specific tag name within the given parent
	*/
	getElementsByTagName(parent, tagName) {
		return parent.getElementsByTagName(tagName);
	}

	/*
	* getChild
	*
	* Return a specific child element by its parent element
	*/
	getChild(parentElement, child) {
		for (var i = 0; i < parentElement.childNodes.length; i++) {
			if (typeof parentElement.childNodes[i].className !== 'undefined' && parentElement.childNodes[i].className.indexOf(child.replace('.', '')) >= 0) {
				return parentElement.childNodes[i];
			}
		}
	}

	/*
	* getChildren
	*
	* Return the children of element
	*/
	getChildren(parentElement) {
		return parentElement.childNodes;
	}

	/*
	* findParentWithClass
	*
	* Return a parent element with a specific class
	*/
	findParentWithClass(element, className) {
		while ((element = element.parentElement) && !element.classList.contains(className));
		return element;
	}

	/*
	* findChildrenWithClass
	*
	* Return a child elements with a specific class
	*/
	findChildrenWithClass(element, className) {
		let childrenWithClass: any = [];
		let children: any = element.children;

		for (var i = 0; i < children.length; i++) {
			if (children[i].classList.contains(className)) {
				childrenWithClass.push(children[i]);
			} else if (children[i].children) {
				childrenWithClass = childrenWithClass.concat(this.findChildrenWithClass(children[i], className));
			}
		}

		return childrenWithClass
	}

	/*
	* setAttribute
	*
	* Set the attribute of a given element to the given values.
	*/
	setAttribute(element, attribute, attributeName) {
		element.setAttribute(attribute, attributeName);
	}

	/*
	* getElementsByName
	*
	* Set the attribute of a given element to the given values.
	*/
	getElementsByName(name) {
		return document.getElementsByName(name);
	}

	/*
	* getAttribute
	*
	* Get the attribute of a given element to the given values.
	*/
	getAttribute(element, attribute) {
		return element.getAttribute(attribute);
	}

	/*
	* getInnerText
	*
	* Get the attribute of a given element to the given values.
	*/
	getInnerText(element) {
		return element.innerText;
	}

	/*
	* addClass
	*
	* Will add a class from an element
	*/
	addClass(element, className) {
		element.classList.add(className);
	}

	/*
	* removeClass
	*
	* Will remove a class from an element
	*/
	removeClass(element, className) {
		element.classList.remove(className);
	}

	/*
	* hasClass
	*
	* Will remove a class from an element
	*/
	hasClass(element, className) {
		return element.classList.contains(className);
	}

	/*
	* get
	*
	* Will get a value from the localStorage
	*/
	getByLocalStorage(name) {
		let value = localStorage.getItem(name);
		if (typeof value == 'undefined' || value == null) return '';

		return value;
	}

	/*
	* get
	*
	* Will get a value from the localStorage
	*/
	getBySessionStorage(name) {
		let value = sessionStorage.getItem(name);
		if (typeof value == 'undefined' || value == null) return '';

		return value;
	}

	/*
	* set
	*
	* Will set a value in the localStorage
	*/
	setByLocalStorage(name, value) {
		localStorage.setItem(name, value)
	}

	/*
	* set
	*
	* Will set a value in the localStorage
	*/
	setBySessionStorage(name, value) {
		sessionStorage.setItem(name, value)
	}

	/*
	* delete
	*
	* Will set a value in the localStorage
	*/
	delete(name) {
		localStorage.removeItem(name)
	}

	/*
	* remove
	*
	* create a remove function because it doesn't exists in IE11
	*/
	remove(element) {
		if (element.parentNode) {
			element.parentNode.removeChild(element);
		}
	}

	/*
	* isIE
	*
	* check if the user is using IE11
	*/
	isIE() {
		return !!(<any>window).MSInputMethodContext && !!(<any>document).documentMode;
	}

	/*
	* formatDate
	*
	* format the date to dd-mm-yyyy
	*/
	formatDate(date, time?){
		// Split the date into parts. Then check if the year is at the front or not.
		let dateParts = date.split('-');
		let yearFirst = false;

		if(dateParts[0].length == 4){
			yearFirst = true;
		}

		// Change the format of the date. Example: 7-3-2000 --> 07-03-2000
		for(let i = 0; i < dateParts.length; i++){
			if(dateParts[i].length == 1){
				dateParts[i] = '0'+dateParts[i];
			}
		}

		// Check the format of the date and the browser. Join the dateParts accordingly.
		if(yearFirst && this.isIE()){
			dateParts = dateParts.reverse().join('-');
		} else if(!yearFirst && !this.isIE()){
			dateParts = dateParts.reverse().join('-');
		} else {
			dateParts = dateParts.join('-');
		}

		// If we have a time, add it to the date
		if(typeof time !== 'undefined' && time != ''){
			dateParts = dateParts +' '+time;
		}

		return dateParts;
	}

	/*
	* getOffset
	*
	* Calculates the top and left offset recursive
	*/
	getOffset(element) {
		var x = 0;
		var y = 0;
		while( element && !isNaN( element.offsetLeft ) && !isNaN( element.offsetTop ) ) {
			x += element.offsetLeft - element.scrollLeft;
			y += element.offsetTop - element.scrollTop;
			element = element.offsetParent;
		}
		return { top: y, left: x };
    }

    /**
    * @author Wilfred van Eck <wilfred@safira.nl>
    */
    empty(variable: any) {
        return (variable == null || variable == undefined || (this.isString(variable) && variable.trim() == ''));
    }

    // Returns if a value is a string
	isString (variable) {
		return typeof variable === 'string' || variable instanceof String;
    }


    /**
     * getBool
     *
     * returns boolean based on the input
     *
     * @param bool
     */
    public getBool(bool: any) {
        if(bool == 1) return true;
        if(bool == 0) return false;
        if(bool == 'true') return true;
        if(bool == 'false') return false;
        if(!bool) return false;
        if(bool) return true;
    }
}
