import { Injectable } from '@angular/core';
import { AjaxService } from '../../core/services/ajax/ajax.service';

@Injectable({
	providedIn: 'root'
})
export class UserModel {
    public userList: any;
    public userInfo: any;
    public isManager = false;
    public isMaster = false;
    public userId: number;
    public userData: any;

	constructor(
        private http: AjaxService
	) {
	}

	/*
	*	login
	*
	*	Login user
	*/
	login(username, password, targetDiv?) {
		let promise = new Promise((resolve, reject) => {
			this.http.post(
				'user/login',
				{ 'username': username, 'password': password },
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
    }

	/*
	*	forgotPassword
	*
	*	Send forgot password mail
	*/
	forgotPassword(username, targetDiv?){
		let promise = new Promise((resolve, reject) => {
			this.http.post(
				'/user/passwordForgot/send',
				{ 'username': username, 'domain' : window.location.protocol + '//' + window.location.host },
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
	}


	/*
	*	saveForgotPassword
	*
	*	Saves the new password
	*/
	saveForgotPassword(password, hash, targetDiv?){
		let promise = new Promise((resolve, reject) => {
			this.http.post(
				'/user/passwordForgot/save',
				{ 'password': password, 'hash' : hash },
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
	}


	/*
	*	register
	*
	*	Registers an user
	*/
	register(username, password, targetDiv?){
		let promise = new Promise((resolve, reject) => {
			this.http.post(
				'/user/register/person',
				{ 'username': username, 'password' : password, 'domain' : window.location.protocol + '//' + window.location.host },
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
	}

	/*
	*	activateUser
	*
	*	Set an user activated
	*/
	activateUser(hash, targetDiv?){
		let promise = new Promise((resolve, reject) => {
			this.http.post(
				'/user/register/activate',
				{ 'hash': hash },
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
	}

	/*
	*	getUserInfo
	*
	*	Returns all user info
	*/
	getUserInfo( targetDiv?){
		let promise = new Promise((resolve, reject) => {
			this.http.get(
				'/user/profile/get',
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
	}

	/*
	*	updateUserInfo
	*
	*	Updates user info
	*/
	updateUserInfo(field, value,  targetDiv?){
		let promise = new Promise((resolve, reject) => {
			this.http.post(
				'/user/profile/updateProfileField',
				{'fieldName' : field, 'value' : value},
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
	}

	/*
	*	changePassword
	*
	*	Changes user password
	*/
	changePassword(oldPassword, newPassword,  targetDiv?){
		let promise = new Promise((resolve, reject) => {
			this.http.post(
				'/user/changePassword',
				{'currentPassword' : oldPassword, 'newPassword' : newPassword},
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
	}

	savePushNotification(token, targetDiv?){
		let promise = new Promise((resolve, reject) => {
			this.http.post(
				'/user/savePushToken',
				{'token' : token},
				targetDiv
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});

		return promise;
	}

	getUserInfoByToken(sessiontoken) {
		let promise = new Promise((resolve, reject) => {
			this.http.get(
				'/user/getUserInfoByToken',
				{'sessionToken' : sessiontoken}
			).then(
				res => { // Success
					resolve(res);
				},
				msg => { // Error
					reject(msg);
				}
			);
		});
		return promise;
    }
    /**
    *
    * get userList
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			07-12-2018 14:11
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public getUserListEmployee(){
        let promise = new Promise((resolve, reject) => {
            this.http.get('user/getUserListEmployee','.main-container').then(
                res => { // Success
                    this.userList = res;
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

        /**
    *
    * get userList
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			07-12-2018 14:11
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
   public getUserList(){
    let promise = new Promise((resolve, reject) => {
        this.http.get('user/getUserList','.main-container').then(
            res => { // Success
                this.userList = res;
                resolve(res);
            },
            msg => {//Error
                reject(msg);
            }
        );
    });
    return promise;
}

/**
*
* Get all ofiices
*
* @author			Romain van Maanen <romain@safira.nl>
* @lastEdit			26-02-2019 08:00
* @lastEditBy		Romain van Maanen <romain@safira.nl>
*/
public getOfficeList(){
    let promise = new Promise((resolve, reject) => {
        this.http.get('user/getOfficeList').then(
            res => { // Success
                this.userList = res;
                resolve(res);
            },
            msg => {//Error
                reject(msg);
            }
        );
    });
    return promise;
}

/**
*
* createNewBookYears
*
* @author			Romain van Maanen <romain@safira.nl>
*/
public createNewBookYears(){
    let promise = new Promise((resolve, reject) => {
        this.http.get('user/createNewBookYears','.main-container').then(
            res => { // Success
                resolve(res);
            },
            msg => {//Error
                reject(msg);
            }
        );
    });
    return promise;
}

     /**
    *
    * get userList
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			07-12-2018 14:11
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
   public getInactifeUserList(){
    let promise = new Promise((resolve, reject) => {
        this.http.get('user/getInactifeUserList','.main-container').then(
            res => { // Success
                this.userList = res;
                resolve(res);
            },
            msg => {//Error
                reject(msg);
            }
        );
    });
    return promise;
}

    /**
    *
    * getInactifeOfficeList
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			26-02-2019 11:50
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public getInactifeOfficeList(){
        let promise = new Promise((resolve, reject) => {
            this.http.get('user/getInactifeOfficeList').then(
                res => { // Success
                    this.userList = res;
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * Get user
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			18-01-2019 08:15
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public getUserById(userId){
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId};
            this.http.post('user/getUserById', data, '.main-container').then(
                res => { // Success
                    this.userInfo = res;
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * checkUserGroups
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			24-01-2019 11:18
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public checkUserGroups(userId){
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId};
            this.http.post('user/checkUserGroups', data, '.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * update user email
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			18-01-2019 13:21
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateUserEmail(userId,email){
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId,'email':email};
            this.http.post('user/updateUserEmail', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * update password
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			18-01-2019 13:57
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateUserPassword(userId, password){
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId, 'password':password};
            this.http.post('user/updateUserPassword', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * update user field
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			18-01-2019 14:17
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateUserField(userId, name, value){
        // value = (value === '') ? 'empty' : value;
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId, 'name':name, 'value':value};
            this.http.post('user/updateUserField', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * update 2fa w
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			18-01-2019 15:38
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateTwoFactorAuthentication(userId, value){
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId, 'value':value};
            this.http.post('user/updateTwoFactorAuthentication', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * updateManagerGroup
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			24-01-2019 10:51
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public updateManagerGroup(userId, value){
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId, 'value':value};
            this.http.post('user/updateManagerGroup', data).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

      /**
    *
    * updateInvoiceGroup
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			24-01-2019 10:51
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
   public updateInvoiceGroup(userId, value){
    let promise = new Promise((resolve, reject) => {
        let data = {'userId':userId, 'value':value};
        this.http.post('user/updateInvoiceGroup', data).then(
            res => { // Success
                resolve(res);
            },
            msg => {//Error
                reject(msg);
            }
        );
    });
    return promise;
}

    /**
    *
    * reset 2fa
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			21-01-2019 07:50
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public resetTwoFactorAuthentication(userId){
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId};
            this.http.post('user/resetTwoFactorAuthentication', data, '.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * createUser
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			21-01-2019 09:32
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public createUser(){
        let promise = new Promise((resolve, reject) => {
            this.http.get('user/createUser','.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * createOffice
    *
    * method to call createOffice endpoint
    *
    * @author			Eric van Doorn <eric@safira.nl
    */
    public createOffice(data){
        let promise = new Promise((resolve, reject) => {
            this.http.post('user/office/createOffice', {'data': data}).then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * createCompanee
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			22-01-2019 14:44
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public createCompanee(){
        let promise = new Promise((resolve, reject) => {
            this.http.get('user/createCompanee', '.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * deactivateUser
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			22-01-2019 11:10
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
    public deactivateUser(userId){
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId};
            this.http.post('user/deactivateUser', data,'.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
    *
    * deactivateUser
    *
    * @author			Romain van Maanen <romain@safira.nl>
    * @lastEdit			22-01-2019 11:10
    * @lastEditBy		Romain van Maanen <romain@safira.nl>
    */
   public activationUser(userId){
        let promise = new Promise((resolve, reject) => {
            let data = {'userId':userId};
            this.http.post('user/activationUser', data,'.main-container').then(
                res => { // Success
                    resolve(res);
                },
                msg => {//Error
                    reject(msg);
                }
            );
        });
        return promise;
    }


    public setUserInfo(data) {

    }

    public createSingleTask(taskData) {
        let promise = new Promise((resolve, reject) => {
            let data = {taskData: taskData};
            this.http.post('myTasks/createSingleTask', data, '.main-container').then(
                res => {
                    resolve(res)
                },
                msg => {
                    reject(msg)
                }
            );
        });
        return promise;
    }
}
